import axios from 'axios';
import ApiConstants from './ApiConstants';
import { DKButton, } from "deskera-ui-library";

export const uploadFile = async (file, fileEntity) => {
  const headers = {
      "x-access-token": ApiConstants.ACCESS_TOKEN,
  };
  
  const formData = new FormData();
  formData.append('file', file);
  formData.append('file-entity', fileEntity);
  try {
      const response = await axios.post(`https://api-dev.deskera.xyz/v1/attachment/file-upload`, formData, { headers });
      if (response.status >= 200 && response.status < 300) {
          return response.data;
      } else {
          console.error(`Request failed with status ${response.status}`);
          console.error(response.statusText);
          return { error: response.statusText };
      }
  } catch (error) {
      console.error(error.message);
      return { 'error': error.message };
  }
};

export function updateRenderedChanges(buttonStates, csvFile, changes, revertChanges, setButtonStates, setRenderedChanges) {
    const renderedChangesArray = [];
    for (let key in changes) {
        if (changes.hasOwnProperty(key) && changes[key] !== null) {
        let value = changes[key];
        const buttonState =
            buttonStates[key] !== undefined ? buttonStates[key] : true;
        renderedChangesArray.push(
            <div className="flex row m-v-m" key={key}>
            {!buttonState
                ? `Changes reverted back '${value}' to '${key}'`
                : `The original was '${key}' we changed it to '${value}'`}
            <DKButton
                className={
                !buttonState
                    ? "btn btn-primary bg-blue text-white ml-l p-v-0"
                    : "btn btn-primary bg-green text-white ml-l p-v-0"
                }
                component="span"
                title={!buttonState ? "Changes reverted" : "Restore changes"}
                onClick={() => {
                revertChanges(key, value, csvFile);
                setButtonStates({
                    ...buttonStates,
                    [key]: false /*!buttonStates[key]*/,
                });
                }}
                disabled={!buttonState}
            />
            </div>
        );
        }
    }
    setRenderedChanges(renderedChangesArray);
}

