import React from 'react';
import chats from '../../assets/chats';
import NewChatBtn from './NewChatBtn';
import UserInfoBtn from './UserInfoBtn';
import backArrow from "../../assets/chatbot/back-arrow.svg";

export default function ChatSidebar( { showSidebar, pageWidth, setShowSidebar }) {
  return (
    <div 
      className='chat-sidebar bg-red d-flex column h-70' 
      style={{
        zIndex: '1',
        width: '16rem',
        // height: '52.5625rem',
        height: '95%',
        borderRadius: '0.95919rem',
        position: 'fixed', 
        backgroundColor: '#FFF', 
        boxShadow: '0px 13.04471px 30.69343px 3.06934px rgba(112, 144, 176, 0.11)',
        fontSize: '0.90444rem',
        fontFamily: 'Plus Jakarta Sans, Inter',
        // color: '#002E74',
        textAlign: 'center',
        fontStyle: 'normal',
        lineHeight: '0.90444rem',
        // width: '5.8225rem',
        left: '2.5%',
        top: '2.5%'
      }}
      >
      <div className='ml-xl fs-xl fw-b pb-l' style={{marginTop: '18%', marginBottom: '6%'}}>Deskera <span className='fw-r'>AI</span></div>
      {
        pageWidth<1300
        &&
        <img src={backArrow} style={{position: 'absolute', right: '4%', top: '1%'}} onClick={() => (setShowSidebar(false))} alt="AI Avatar" />
      }
      <div className='w-100 border-s'></div>
      <small className='ml-l mt-l' style={{color: '#9D9D9D', fontWeight: '400'}}>TODAY</small>
      <div className='w-100 pt-m custom-scroll' style={{overflow: 'auto', height: '70%'}}>
        {chats.map((chat, index) => {
          return (
            <div 
              className='d-flex pl-l align-items-center ml-s' 
              style={{marginBottom: '10%', fontSize: '0.90444rem', fontWeight: '500'}}
            >
              <span>
                {chat.icon}
              </span>
              <span className='chat-name pl-s' style={{color: `${index!==0 && '#C6CCD5'}`}}>
                {chat.name<20 ? chat.name : chat.name.substring(0, 20) + (chat.name.length > 20 ? '...' : '')}
              </span>
            </div>
          )
        })}
      </div>
      <div className='d-flex column w-100 p-s bg-white' style={{position: 'absolute', bottom: '1%'}}>
        <NewChatBtn />
        <UserInfoBtn />
      </div>
    </div>
  )
}

// import React from 'react';
// import chats from '../../assets/chats';
// import NewChatBtn from './NewChatBtn';
// import UserInfoBtn from './UserInfoBtn';

// export default function ChatSidebar() {
//   return (
//     <div 
//       className='sidebar bg-red d-flex column h-70' 
//       style={{
//         width: '15%', 
//         height: '90%', 
//         position: 'absolute', 
//         top: '5%', 
//         left: '2%', 
//         borderRadius: '0.95919rem', 
//         backgroundColor: '#FFF', 
//         boxShadow: '0px 13.04471px 30.69343px 3.06934px rgba(112, 144, 176, 0.11)',
//         fontSize: '0.90444rem'
//       }}
//       >
//       <div className='ml-l mt-xxl fs-xl fw-b pb-l'>Deskera <span className='fw-r'>AI</span></div>
//       <div className='w-100 border-s'></div>
//       <div className='w-100 pt-m custom-scroll' style={{overflow: 'auto', height: '70%'}}>{chats.map(chat => {
//           return (
//             <div className='d-flex fw-m mt-s pl-l'>
//               <span>
//                 {chat.icon}
//               </span>
//               <span className='pl-s'>
//                 {chat.name<20 ? chat.name : chat.name.substring(0, 20) + (chat.name.length > 20 ? '...' : '')}
//               </span>
//             </div>
//           )
//         })}
//       </div>
//       <div className='d-flex column w-100 p-s bg-white' style={{position: 'absolute', bottom: '1%'}}>
//         <NewChatBtn />
//         <UserInfoBtn />
//       </div>
//     </div>
//   )
// }
