import React from 'react';
import plus from '../../assets/chatbot/plus.svg';
import send from '../../assets/chatbot/send.svg';

export default function ChatInput({ setInput, chatLog, handleSubmit, input, handleChange, loading, handleClick }) {
  return (
    <div>
      <div className='chat-input-container border-m border-radius-r p-m d-flex row justify-content-between' style={{ width: '55%', position: 'fixed', bottom: '1%', left: '29%', backgroundColor: '#fcfcfc', marginBottom: '2%' }}>
          {
            chatLog && chatLog.length>1 && chatLog[chatLog.length-1].user === 'bot' &&
            (<div className='d-flex align-items-center' style={{position: 'absolute', bottom: '5rem'}}>
              {chatLog && chatLog[chatLog.length-1] && chatLog[chatLog.length-1].suggestions && <span>Suggestions: </span>}
                {chatLog && chatLog[chatLog.length-1] && chatLog[chatLog.length-1].suggestions && chatLog[chatLog.length-1].suggestions.map((suggestion, index) => {
                  return (
                    <div 
                      style={{marginLeft: '1rem', backgroundColor: '#FDFDFD'}} 
                      onClick={() => {
                        setInput(suggestion);
                      }}
                      className='border-m border-radius-m p-m cursor-pointer'>
                        {suggestion}
                      </div>
                    )
                })}
            </div>)
          }
          <div className='d-flex w-100 align-items-center'>
            <div className='mr-s' style={{ cursor: 'pointer' }}><img src={plus} alt="AI Avatar" /></div>
            <form onSubmit={handleSubmit} className='w-100'>
              <input 
                  id='chat-input'
                  type='text'
                  value={input}
                  onChange={handleChange}
                  placeholder='Type message'
                  // className='p-l border-m border-radius-m'
                  className='w-100'
                  style={{ border: 'none', outline: 'none', cursor: loading && 'not-allowed', fontSize: '0.95rem', backgroundColor: 'rgb(252,252,252)', padding: '1%' , fontWeight:"400", fontFamily:"Inter"}}
                  disabled={loading}
                  autoComplete='off'
              />
            </form>
          </div>
        <div className='ml-s' onClick={() => !loading && handleSubmit()} style={{ cursor: loading ? 'not-allowed' : 'pointer' }}><img src={send} alt="AI Avatar" /></div>
      </div>
    </div>
  )
}
