import { DKIcons } from "deskera-ui-library";
import message from './chatbot/message.svg';
import messageLight from './chatbot/message_light.svg';

const chatList = [
    {
        id: 1,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #1'
            }
        ],
        name: 'Advantages of AI',
        icon: <img src={message} alt="AI Avatar" />
    },
    {
        id: 2,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #2'
            }
        ],
        name: 'PNL Analysis',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 3,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Cashflow insights',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 4,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'PNL Analysis',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 5,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Cashflow insights',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 6,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Import masters',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 7,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #1'
            }
        ],
        name: 'PNL Analysis',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 8,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #2'
            }
        ],
        name: 'PNL Analysis',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 9,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Cashflow insights',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 10,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'PNL Analysis',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 11,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Cashflow insights',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 12,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Import masters',
        icon: <img src={messageLight} alt="AI Avatar" />
    },{
        id: 1,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #1'
            }
        ],
        name: 'Import from QB',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 2,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #2'
            }
        ],
        name: 'PNL Analysis',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 3,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Cashflow insights',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 4,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'PNL Analysis',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 5,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Cashflow insights',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 6,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Import masters',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 7,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #1'
            }
        ],
        name: 'PNL Analysis',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 8,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #2'
            }
        ],
        name: 'PNL Analysis',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 9,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Cashflow insights',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 10,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'PNL Analysis',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 11,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Cashflow insights',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
    {
        id: 12,
        chatLog: [
            {
                user: 'bot',
                message: 'How may I assist you? #3'
            }
        ],
        name: 'Import masters',
        icon: <img src={messageLight} alt="AI Avatar" />
    },
]

export default chatList;