import React, { useEffect, useState } from "react";
import axios from "axios";
import { DKButton, DKSpinner, DKIconText, DKIcons, showToast } from "deskera-ui-library";
import ApiConstants from "../../../../utils/ApiConstants";
import TenantManager from "../../../../managers/TenantManager";
import ChartOfAccounts from './ChartOfAccounts';
import { saveAs } from "file-saver";
import { uploadFile, updateRenderedChanges } from "../../../../utils/ImportUtils";
import { colMappingAndImportProduct, fileProcessor, checkIfNewAccounts } from "../../../../utils/ProductImportUtils";
import sampleQbProductFile from '../../../../assets/chatbot/qb/sampleQbProductFile.csv';
import uploadCloudOutlinedImg from '../../../../assets/chatbot/uploadCloudOutlinedImg.png';

export default function Product() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState(null);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [renderedChanges, setRenderedChanges] = useState([]);
  const [changes, setChanges] = useState({});
  const [csvFile, setCsvFile] = useState("");
  const [changesReverted, setChangesReverted] = useState(false);
  const [buttonStates, setButtonStates] = useState({});
  const [deskeraRelativePath, setDeskeraRelativePath] = useState("");
  const [tenantDetails, setTenantDeatils] = useState(TenantManager.getTenantDetails());
  const [processedFileResponse, setProcessedFileResponse] = useState();
  const [showContinue, setShowContinue] = useState(false);
  const [coaIsSuccessful, setCoaIsSuccessful] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [invalidImports, setInvalidImports] = useState([]);
  const [successImports, setSuccessImports] = useState([]);
  const [continueClicked, setContinueClicked] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [sampleFile, setSampleFile] = useState();

  
  const stringToCSV = (csvFile) => {
    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    let file = new File([blob], "ds_product.csv");
    setCsvFile(file);
    // setCsvFile(selectedFile);
  }


  const handleDataFromChild = (data) => {
    setFileIsUploading(true);
    setCoaIsSuccessful(data);
  };

  function revertChanges(original, changed, csvFile) {
    setChangesReverted(true);
    setCsvFile((prevCsvFile) => prevCsvFile.toString().replace(changed, original));
  }

  function resetState() {
    console.log('resetting state');
    setCsvFile();
    setButtonStates({});
    setSelectedFile(null);
    setRenderedChanges([]);
    setChanges({});
    setProcessedFileResponse([]);
  }

  // 1. [buttonStates, csvFile, changes]
  useEffect(() => {
    updateRenderedChanges(buttonStates, csvFile, changes, revertChanges, setButtonStates, setRenderedChanges);
  }, [buttonStates, csvFile, changes]);


  // 2. [showContinue]
  useEffect(() => {
    setProcessedFileResponse(null);
    console.log('Processed file response set to NULL');
  }, [showContinue])

  // 3. [formData, coaIsSuccessful]
  useEffect(() => {
    coaIsSuccessful && !showContinue
    &&
    fileProcessor(formData, showContinue, setDeskeraRelativePath,  setShowContinue, setChanges, setFileIsUploading, setSelectedFile, setErrorMsg, setCsvFile)
  }, [formData, coaIsSuccessful])

  // 4. [formData]
  useEffect(() => {
    formData && formData.has('file') && formData.has('col_headers') && selectedFile && !showContinue &&
    checkIfNewAccounts(formData, setFileIsUploading, setProcessedFileResponse, setCoaIsSuccessful, setSelectedFile, setErrorMsg)
  }, [formData])

  // 5. [selectedFile]
  useEffect(() => {
    let updatedFormData = new FormData();
    updatedFormData.append("file", selectedFile);
    updatedFormData.append("col_headers", '["Income Account", "Expense Account", "Inventory Asset Account"]');
    setFormData(updatedFormData);
  }, [selectedFile])

  // 6. [deskeraRelativePath]
  useEffect(() => {
    deskeraRelativePath && continueClicked && colMappingAndImportProduct(setIsCompleted, setFileIsUploading, setSuccessImports, setInvalidImports, deskeraRelativePath);
  }, [deskeraRelativePath])

  // 7. [csvFile]
  useEffect(() => {
    if(showContinue) {
      // get the relative path of most updated csv file
      let updatedFormData = new FormData();
      updatedFormData.append("file", csvFile);
      updatedFormData.append("col_headers", '["Income Account", "Expense Account", "Inventory Asset Account"]');
      setFormData(updatedFormData);
    }
    if(continueClicked) {
      if(changesReverted) {
        uploadFile(csvFile, 'IMPORT_PRODUCT')
        .then(response => {
          console.log('uploadFile SUCCESSFUL!', response); // This will log the response from uploadFile
          setDeskeraRelativePath(response.relativePath);
        })
        .catch(error => {
          console.error(error);
        });
      } else {
        colMappingAndImportProduct(setIsCompleted, setFileIsUploading, setSuccessImports, setInvalidImports, deskeraRelativePath); 
      }
    } 
  }, [csvFile])

  const handleDownload = async () => {
    const link = document.createElement('a');
    link.href = sampleQbProductFile;
    link.download = 'sampleQbProductFile';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleFileChange = (e) => {
    setErrorMsg(null);
    setFileIsUploading(true);
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    isCompleted
    ?
    (
      <div 
        className={`d-flex justify-content-start message p-h-s w-100 border-radius-m p-m fs-l fw-m pt-0 pb-0 border-none`}
        style={{
            boxShadow: '14px 27px 60px 4px rgba(112, 144, 176, 0.08)',
            backgroundColor: '#FFF'
        }}
      >
        <div>
          {/* {showToast('Product File Imported Successfully', 'success', 'top')} */}
          <div>{successImports.length} products successfully imported into Deskera</div>
          {invalidImports.length>0 && <div>{invalidImports.length} failed to import</div>}
          {invalidImports.length>0 && <div>
            Failure reasons:
            {invalidImports && invalidImports.map((item, index) => (
              <li key={index}><b>{item.name}</b> <span className='text-orange'>failed</span> to import due to {
                item.errors.map((error) => (
                  <span>{error} , </span>
                ))
              }</li>
            ))}
          </div>}
        </div>
        <h3 className='text-green'>Product Import Successful!</h3>
      </div>
    )
    :
    <div
      className={`import-qb-product-container flex column d-flex justify-content-start message p-h-s w-100 border-radius-m p-m fs-l fw-m pb-0 border-none`}
      style={{
          boxShadow: '14px 27px 60px 4px rgba(112, 144, 176, 0.08)',
          backgroundColor: '#FFF'
      }}
    >
      {
        !selectedFile && 
        <div className='upload-qb-product-file-container column align-items-center bg-white border-radius-s shadow-s p-l'>
          <div className='upload-qb-product-file-header text-black text-gray mb-s'>Upload Quickbooks Product file</div>
          <div className='upload-qb-product-file-btn-container bg-line m-s ml-l mr-l flex column align-items-center p-s border-radius-s text-black'>
            <img class="m-r" src={uploadCloudOutlinedImg} alt="Upload Cloud" />
            <div className='upload-file-btn flex column align-items-center m-s'>
              <small className='mb-s'>Upload .xlsx, .csv or .xml</small>
              <input
                type="file"
                accept=".xlsx,.csv"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="product-file-upload-input"
                disabled={fileIsUploading}
              />
              <label htmlFor="product-file-upload-input">
                {<DKIconText
                  className="btn btn-primary bg-blue text-white p-s border-radius-m cursor-hand"
                  component="span"
                  icon={DKIcons.white.ic_document}
                  iconClassName=""
                  text={
                    !selectedFile
                      ? "Choose a file"
                      : "Choose another file"
                  }
                  disabled={fileIsUploading}
                  // textClassName="fs-s"
                  onClick={() => resetState()}
                />}
              </label>
            </div>
            <div className='sample-file-btn'>
              <DKIconText
                className="btn btn-primary p-s border-s border-blue border-radius-m cursor-hand m-xl"
                component="span"
                icon={DKIcons.ic_download}
                iconClassName=""
                text="Sample File"
                disabled={fileIsUploading}
                // textClassName="fs-s"
                onClick={handleDownload}
              />
            </div>
          </div>
        </div>
      }
      {
        (
          (processedFileResponse && processedFileResponse.length>0)
          ?
          (
            !coaIsSuccessful 
            ? 
            // prompt user to upload COA
            <div>
              <div>The following accounts have not been created / imported:</div>
              <div>
                {processedFileResponse.map((item, index) => (
                  <li key={index}><b className='text-orange'>{item}</b> does <span className='text-red'>not</span> exist in the system</li>
                ))}
              </div>
              <p>To resolve this issue, please import Chart Of Accounts first</p>
              <ChartOfAccounts onData={handleDataFromChild}/>
            </div>
            :
            // Loading (while the file is processing)
            <div>
              <div>
                {processedFileResponse.map((item, index) => (
                  <li key={index}><b className='text-green'>{item}</b> now exist in the system</li>
                ))}
                <div><DKSpinner className="m-v-m" iconClassName="ic-s" title="Processing your file" isWhite /></div>
              </div>
            </div>
          )
          :
          (
            fileIsUploading 
            ? 
            <div><DKSpinner className="m-v-m" iconClassName="ic-s" title="Processing your file" isWhite /></div>
            :
            selectedFile && <div className="logs">
              <div className="m-m">
                <span><b>{selectedFile.name}</b> imported successfully</span>
                <div
                  className="logs-container p-s"
                  style={{ maxHeight: "35vh", overflowY: "auto" }}
                >
                  {renderedChanges}
                </div>
              </div>
            </div>
          )
        )
      }

      {selectedFile && !fileIsUploading && showContinue && (
        // Continue btn (post product-file processing)
        <DKButton
          className="btn btn-primary bg-blue text-white"
          component="span"
          title="Continue"
          onClick={() => {
            setContinueClicked(true);
            setFileIsUploading(true);
            stringToCSV();
          }}
        />
      )}
      {errorMsg}
    </div>
  );
}
