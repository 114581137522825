// import { NotificationsUpdate } from "../services/NotificationService";
import AppManager from "./AppManager";
// import PlanManager from "./PlanManager";
// import AddOnManager from "./AddOnManager";
// import IntercomManager from "./IntercomManager";

export const PAGE_ROUTES = {
  DASHBOARD: "/",
  
};
export default class RouteManager {
  static presenter = null;
  static setPresenter(presenter) {
    this.presenter = presenter;
    // NotificationsUpdate.sendMessage("Route change");
  }
  static navigateToPage(pageRoute, param = null) {
   
    AppManager.scrollToTop();
  }

  static navigateToHome() {
    RouteManager.navigateToPage(PAGE_ROUTES.DASHBOARD);
  }
}
