import React, { useEffect, useState } from "react";
import axios from "axios";
import { DKButton, DKSpinner, DKIconText, DKIcons, showToast } from "deskera-ui-library";
import ApiConstants from "../../../../utils/ApiConstants";
import TenantManager from "../../../../managers/TenantManager";
import { saveAs } from "file-saver";
import { fileProcessor, colMappingAndImportCoa } from "../../../../utils/CoaImportUtils";
import { updateRenderedChanges } from "../../../../utils/ImportUtils";
import sampleQbCoaFile from '../../../../assets/chatbot/qb/sampleQbCoaFile.xlsx';
import uploadCloudOutlinedImg from '../../../../assets/chatbot/uploadCloudOutlinedImg.png';


export default function ChartOfAccounts({ onData }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState(null);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [renderedChanges, setRenderedChanges] = useState([]);
  const [changes, setChanges] = useState({});
  const [csvFile, setCsvFile] = useState("");
  const [buttonStates, setButtonStates] = useState({});
  const [deskeraRelativePath, setDeskeraRelativePath] = useState("");
  const [tenantDetails, setTenantDeatils] = useState(TenantManager.getTenantDetails());
  const [moduleName, setModuleName] = useState('ACCOUNT');
  const [coaInvalidImports, setCoaInvalidImports] = useState(null);
  const [coaSuccessImports, setCoaSuccessImports] = useState(null);
  const [obInvalidImports, setObInvalidImports] = useState(null);
  const [obSuccessImports, setObSuccessImports] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const sendDataToParent = () => {
    onData && onData(true);
  }

  function revertChanges(original, changed, csvFile) {
    setCsvFile((prevCsvFile) => prevCsvFile.replace(changed, original));
  }

  function resetState() {
    console.log('resetting state');
    setCsvFile("");
    setButtonStates({});
    setSelectedFile(null);
    setRenderedChanges([]);
    setChanges({});
  }

  useEffect(() => {
    updateRenderedChanges(buttonStates, csvFile, changes, revertChanges, setButtonStates, setRenderedChanges);
  }, [buttonStates, csvFile, changes]);


  // 1. [formData]
  useEffect(() => {
    selectedFile 
    &&
    fileProcessor(formData, setDeskeraRelativePath, setChanges, setCsvFile, setFileIsUploading, setSelectedFile, setErrorMsg)
  }, [formData])

  // 2. [selectedFile] 
  useEffect(() => {
    let updatedFormData = new FormData();
    updatedFormData.append("file", selectedFile);
    updatedFormData.append("currency", tenantDetails.currency);
    setFormData(updatedFormData);
    // setFileIsUploading(false);
  }, [selectedFile])

  const handleDownload = async () => {
    const link = document.createElement('a');
    link.href = sampleQbCoaFile;
    link.download = 'sampleQbCoaFile';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileChange = (e) => {
    setErrorMsg(null);
    setFileIsUploading(true);
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div className="import-qb-coa-container flex column">
      {
        !selectedFile && 
        <div className='upload-qb-coa-file-container column align-items-center bg-white border-radius-s shadow-s p-l'>
          <div className='upload-qb-coa-file-header text-black text-gray mb-s'>Upload Quickbooks Chart of Accounts file</div>
          <div className='upload-qb-coa-file-btn-container bg-line m-s ml-l mr-l flex column align-items-center p-s border-radius-s text-black'>
            <img class="m-r" src={uploadCloudOutlinedImg} alt="Upload Cloud" />
            <div className='upload-file-btn flex column align-items-center m-s'>
              <small className='mb-s'>Upload .xlsx, .csv or .xml</small>
              <input
                type="file"
                accept=".xlsx,.csv"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="coa-file-upload-input"
                disabled={fileIsUploading}
              />
              <label htmlFor="coa-file-upload-input">
                {<DKIconText
                  className="btn btn-primary bg-blue text-white p-s border-radius-m cursor-hand"
                  component="span"
                  icon={DKIcons.white.ic_document}
                  iconClassName=""
                  text={
                    !selectedFile
                      ? "Choose a file"
                      : "Choose another file"
                  }
                  disabled={fileIsUploading}
                  // textClassName="fs-s"
                  onClick={() => resetState()}
                />}
              </label>
            </div>
            <div className='sample-file-btn'>
              <DKIconText
                className="btn btn-primary p-s border-s border-blue border-radius-m cursor-hand m-xl"
                component="span"
                icon={DKIcons.ic_download}
                iconClassName=""
                text="Sample File"
                disabled={fileIsUploading}
                // textClassName="fs-s"
                onClick={handleDownload}
              />
            </div>
          </div>
        </div>
      }
      {
        fileIsUploading && <div><DKSpinner className="m-v-m" iconClassName="ic-s" title="Processing your file" isWhite /></div>
      }
      {
        !fileIsUploading && selectedFile && <div className="logs">
          <div>
            {moduleName==='ACCOUNT' && <div className="mb-m">This has been uploaded as your QB's File: <b>{selectedFile.name}</b></div>}
            {renderedChanges.length>0 && <div className="logs-container p-s" style={{ overflowY: "auto" }}>{renderedChanges}</div>}
            {
              moduleName==='ACCOUNT_OPENING' 
              && 
              !isCompleted 
              && 
              <div className="mb-m">
                We've detected Opening Balance in your uploaded QB file. Click on continue to import those.
              </div>
            }
          </div>
        </div>
      }
      {
        !fileIsUploading && selectedFile && ((coaSuccessImports) || (coaInvalidImports && coaInvalidImports.length>0))
        &&
        <p>COA:-</p>
      }
      {
        !fileIsUploading && selectedFile && coaSuccessImports
        &&
        <div>
          {coaSuccessImports.length} accounts <b>successfully</b> imported 
        </div>
      }
      {
        !fileIsUploading && selectedFile && coaInvalidImports && coaInvalidImports.length>0
        &&
        <div>
          {coaInvalidImports.length} <b>failed</b> to import<br></br>
          Failure reasons:<br></br>
          <div style={{maxHeight: '35vh', overflowY: 'auto'}}>
            {coaInvalidImports.map((item, index) => (
            <li key={index}><b>{item.name}</b> <span className='text-orange'>failed</span> to import due to {
              item.errors.map((error) => (
                <span>{error} , </span>
              ))
            }</li>
          ))}
          </div>
        </div>
      }
      <br></br>
      {
        !fileIsUploading && selectedFile && ((obSuccessImports) || (obInvalidImports && obInvalidImports.length>0))
        &&
        <p>Opening Balance:-</p>
      }
      {
        !fileIsUploading && selectedFile && obSuccessImports
        &&
        <div>
          {obSuccessImports.length} accounts <b>successfully</b> imported 
        </div>
      }
      {
        !fileIsUploading && selectedFile && obInvalidImports && obInvalidImports.length>0
        &&
        <div>
          {obInvalidImports.length} <b>failed</b> to import<br></br>
          Failure reasons:<br></br>
          <div style={{maxHeight: '35vh', overflowY: 'auto'}}>
            {obInvalidImports.map((item, index) => (
            <li key={index}><b>{item.name}</b> <span className='text-orange'>failed</span> to import due to {
              item.errors.map((error) => (
                <span>{error} , </span>
              ))
            }</li>
          ))}
          </div>
        </div>
      }

      {
        selectedFile 
        && 
        !fileIsUploading 
        && 
        (
          !isCompleted 
          ?
          <DKButton
            className="btn btn-primary bg-blue text-white"
            component="span"
            title="Continue"
            onClick={() => {
              setFileIsUploading(true)
              colMappingAndImportCoa(moduleName, deskeraRelativePath, tenantDetails, selectedFile, setFileIsUploading, setCoaSuccessImports, setCoaInvalidImports, setModuleName, setDeskeraRelativePath, setChanges, setCsvFile, setSelectedFile, setErrorMsg, setObInvalidImports, setObSuccessImports, sendDataToParent, setIsCompleted)
            }}
          />
          :
          <h3>Successfully imported COA and Opening Balance files</h3>
      )}
      {errorMsg}
    </div>
  );
}
