import React from 'react';
import plusWhite from '../../assets/chatbot/plus_white.svg';

export default function NewChatBtn() {
  return (
    <button className='d-flex row bg-dark-gray border-radius-xxl p-m cursor-pointer' style={{ fontSize:"15px",padding:"18px 15px",  fontWeight:"400", fontFamily:"Inter"}}>
      <img src={plusWhite} alt=''/><span className='text-white fw-m ml-m' style={{ fontSize:"15px", fontWeight:"600", fontFamily:"Inter"}}>New Chat</span>
    </button>
  )
}
