import React from 'react'
import { DKSpinner, DKIcon, DKInput, DKIcons, DKSearchBar } from 'deskera-ui-library';
// import ic_profile_pic from "../../assets/menu/ic_profile_pic.png";
import userAvatar  from "../../assets/chatbot/user-avatar.svg";
import aiAvatar from "../../assets/chatbot/ai-avatar.svg";

export default function ChatMessage({ message, size, index, componentMap, isDarkMode, pageWidth }) {
    

    message.graph && console.log('message.graph.graph_data:', message.graph.graph_data);
    return (
        <div className={`chat-message-container chat-message-${message.user === 'bot' && 'chatbot'} w-100`}>
            <div className='chat-message-sub-container d-flex p-l' style={{maxWidth: '60%', marginLeft: 'auto', marginRight: 'auto'}}>
                <div className={`avatar-${message.user === 'bot' ? 'chatbot' : 'user'}`}>
                    {
                        message.user === 'bot' 
                        ? 
                        <img src={aiAvatar} alt="AI Avatar" />
                        : 
                        // <DKIcon src={ic_profile_pic} className="circle ic-r-3 bg-white mr-s" />
                        <img src={userAvatar} alt="User Avatar" />
                    }
                </div>
                
                {
                    componentMap.hasOwnProperty(message.message) 
                    ? 
                    (
                        index===size-1 
                        ? 
                        <div className='message p-h-s'>{componentMap[message.message]}</div> 
                        : 
                        <div className={`d-flex justify-content-start message w-100 border-radius-l fs-l fw-m border-none p-s pl-l`}
                            style={{
                                boxShadow: message.user==='bot' && '14px 27px 60px 4px rgba(142, 147, 255, 0.08)',
                                backgroundColor: message.user==='bot' &&'#FFF'
                            }}
                            >
                                <p>This process has been completed/ terminated</p>
                            </div>
                    ) 
                    : 
                    (
                        message.user !== 'bot'
                        ?
                        <div
                            className={`main-answer-container d-flex justify-content-start message w-100 border-radius-l fs-l 
                                ${message.user==='bot'
                                ? 
                                'fw-m border-none p-s pl-l' 
                                : 
                                'fw-b border-m  p-l'}` }
                                style={{
                                    boxShadow: message.user==='bot' && '14px 27px 60px 4px rgba(142, 147, 255, 0.08)',
                                    backgroundColor: message.user==='bot' &&'#FFF',
                                    fontWeight: '450',
                                    lineHeight:"28px",
                                    fontSize:"15px",
                                    flexDirection: ((message.graph) && (message.graph.insight || message.graph.outro)) ? 'column' : 'row'
                                }}
                        >
                            {message.message}
                        </div>
                        :
                        <div className='w-100'>
                            <div
                                className={`main-answer-container d-flex justify-content-start message border-radius-l fs-l fw-m border-none p-s pl-l`}
                                style={{
                                    boxShadow: '14px 27px 60px 4px rgba(142, 147, 255, 0.08)',
                                    backgroundColor: '#FFF',
                                    fontWeight: '400',
                                    lineHeight:"26px",
                                    fontSize:"15px",
                                    flexDirection: ((message.graph) && (message.graph.insight || message.graph.outro)) ? 'column' : 'row'
                                }}
                            >
                                {message.message}
                            </div>
                            {
                                ((message.graph && message.graph.insight) || (message.graph && message.graph.graph_data)) && 
                                <div className={`d-flex mt-l ${pageWidth<1300 && 'column'}`}>
                                    {(message.graph && message.graph.graph_data) ? <div className='border-m m-s p-s pr-xxl custom-scroll scrollbar-thin' style={{flex: '2.4', height: '20rem', overflowX: 'scroll', overflowY: 'hidden', borderRadius: '0.875rem', padding: '0.8rem 0.7rem', fontFamily: 'Inter', fontWeight: '500', fontSize: '0.875rem'}}>{message.graph.graph_data}</div> : <div className='d-flex justify-content-center align-items-center border-m m-s custom-scroll scrollbar-thin' style={{flex: '2.4', height: '20rem', borderRadius: '0.875rem', padding: '0.8rem 0.7rem'}}><p><DKSpinner className="mr-l" iconClassName="ic-s" title="" /></p></div>}
                                    <small className='m-s' style={{flex: '1.6', height: '20rem', overflow: 'auto', borderRadius: '0.875rem', padding: '0.7rem 0.5rem', wordWrap: "break-word", backgroundColor: 'rgb(217,245,242)', color: '#008C6B', fontFamily: 'Inter', fontWeight: '500', fontSize: '0.95rem', lineHeight:"22px"}}>{message.graph.insight}</small>
                                </div>
                            }
                            {
                                message.graph && message.graph.outro && 
                                <div 
                                    className='border-radius-l fs-l fw-m border-none p-s pl-m  mt-m'
                                    style={{
                                        boxShadow: '14px 27px 60px 4px rgba(142, 147, 255, 0.08)',
                                        backgroundColor: '#FFF',
                                        fontWeight: '400',
                                        lineHeight:"28px",
                                        fontSize:"0.95rem",
                                    }}
                                >
                                    {message.graph.outro}
                                </div>
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}