import { useEffect, useState } from "react";
import { DKLabel, LineChart } from "deskera-ui-library";
import GraphManager from "../../managers/GraphManager";
import { CHART_INTERVALS_CLAIMS_TREND } from "../../utils/Constants";
import DKDropdown from "../common/DKDropdown";
import { COLORS } from "../../utils/Colors";
import DashboardService from "../../services/DashboardService";
import { getDashboardWidgetLoading } from "../dashboard/Dashboard";
import moment from "moment";
import FormatCurrency from "../../sharedComponents/FormatCurrency";

const ExpenseClaimTrends = () => {
  const [selectedInterval, setSelectedInterval] = useState(
    GraphManager.get("claimByTrend").interval
  );
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [claimsData, setClaimsData] = useState([]);

  useEffect(() => {
    const filterData = (data) => {
      if (
        selectedInterval.interval ===
          CHART_INTERVALS_CLAIMS_TREND[0].interval ||
        selectedInterval.interval === CHART_INTERVALS_CLAIMS_TREND[1].interval
      ) {
        data = data.map((item) => ({
          value: item.amount,
          title:
            selectedInterval.interval ===
            CHART_INTERVALS_CLAIMS_TREND[1].interval
              ? moment(item.axisValue, "Do MMM YYYY").get("D")
              : moment(item.axisValue, "Do MMM YYYY").format("ddd"),
          ...item,
        }));
      } else if (
        selectedInterval.interval ===
          CHART_INTERVALS_CLAIMS_TREND[2].interval ||
        selectedInterval.interval === CHART_INTERVALS_CLAIMS_TREND[3].interval
      ) {
        data = data.map((item) => ({
          value: item.amount,
          title: moment(item.axisValue, "MMM YYYY").format("MMM"),
          ...item,
        }));
      }
      setData([
        {
          title: "Amount",
          color: COLORS.charts.line.app_green,
          points: data,
        },
      ]);
    };

    function getData() {
      const interval = selectedInterval.searchKey;
      DashboardService.getClaimsTrendByInterval(interval).then((data) => {
        filterData(data?.data);
        setClaimsData(data);
        setShowLoader(false);
        GraphManager.set("claimByTrend", {
          data: data.data,
          interval: selectedInterval,
        });
      });
    }
    if (GraphManager.hasData("claimByTrend")) {
      filterData(GraphManager.get("claimByTrend").data);
      setTimeout(getData, 100);
    } else {
      setShowLoader(true);
      getData();
    }
  }, [selectedInterval]);

  return (
    <>
      <div className="bg-white p-h-xl pt-r pb-l border-m border-radius-m border-box dashboard-card column align-items-center parent-width">
        <div className="row align-items-center justify-content-between mb-s">
          <DKLabel text={"Expense Claims Trend"} className="fw-m fs-m" />
          {selectedInterval && (
            <div className="column">
              <DKDropdown
                title={selectedInterval.name}
                data={CHART_INTERVALS_CLAIMS_TREND.map(
                  (interval) => interval.name
                )}
                onSelect={(index) => {
                  GraphManager.clear("claimByTrend");
                  setSelectedInterval(
                    Object.assign({}, CHART_INTERVALS_CLAIMS_TREND[index])
                  );
                }}
              />
            </div>
          )}
        </div>
        <div className="row align-items-center justify-content-between">
          <div className="column">
            <p className="m-v-xs"> Total Claim Amount</p>
            <FormatCurrency
              className="fw-m fs-m"
              amount={
                claimsData?.["totalClaimAmount"]
                  ? claimsData?.["totalClaimAmount"]
                  : 0
              }
            />
          </div>
          <div className="column">
            <p className="m-v-xs"> Highest Claim Amount</p>
            <FormatCurrency
              className="fw-m fs-m"
              amount={
                claimsData?.["maxClaimAmount"]
                  ? claimsData?.["maxClaimAmount"]
                  : 0
              }
            />
          </div>
        </div>
        {!showLoader && data.length > 0 && (
          <LineChart data={data} height={180} />
        )}
        {showLoader && getDashboardWidgetLoading()}
      </div>
    </>
  );
};

export default ExpenseClaimTrends;
