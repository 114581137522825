import { Cell } from 'recharts';
import menu from '../../assets/chatbot/hamburger.svg';

export default function ChatHeader({ pageWidth, showSidebar, setShowSidebar }) {
  return (
    <div className='header d-flex row justify-content-center fs-l fw-m'>
      {
        (!showSidebar && pageWidth<1300)
        &&
        <img 
          src={menu} 
          alt="hamburger-menu" 
          style={{position: 'absolute', left: '6.4%', right: '5%', top: '3%', display: 'none'}}
          onClick={() => {setShowSidebar(true)}}
        />
      }
      <div className={`border-m border-radius-m p-s mt-m d-flex justify-content-between align-items-center`} style={{fontWeight: '400', marginBottom:"20px", justifyContent:'center'}} >💬 Chat with Deskera AI</div>
    </div>
  )
}
