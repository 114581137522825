import axios from 'axios';
import ApiConstants from './ApiConstants';

export function fileProcessor(formData, setDeskeraRelativePath, setChanges, setCsvFile, setFileIsUploading, setSelectedFile, setErrorMsg) {
    try {
        axios.post(`${ApiConstants.URL.CHAT.BASE_FILE_PROCESSING}/file-processor?module=IMPORT_INVOICE`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-access-token': ApiConstants.ACCESS_TOKEN
            }
        })
        .then((response) => {
            console.log('response:', response);
            const relativePath = response.data.ds_relative_file_path;
            setDeskeraRelativePath(relativePath)
            const changesData = response.data.corrections;
            setChanges(changesData);
            axios.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.CHAT.ATTACHMENT_FILE_DOWNLOAD}${relativePath}`, {
                headers: {
                    'Accept': '*/*',
                    'x-access-token': ApiConstants.ACCESS_TOKEN
                },
            })
                .then((response) => {
                    const downloadURL = response.data;
                    axios.get(`${downloadURL}`, {
                        headers: {
                            'Accept': '*/*',
                            'x-access-token': ApiConstants.ACCESS_TOKEN
                        },
                    })
                        .then((response) => {
                            setCsvFile(response.data);
                                // saveAs(new Blob([response.data]), './reverted.csv');
                            setFileIsUploading(false);
                        })
                        .catch((error) => {
                            setFileIsUploading(false);
                            setSelectedFile(null);
                            setErrorMsg(<h4>Looks like there's an <span className='text-orange'>{error}</span> while uploading this file, I request you to please try again!</h4>);
                        });
                })
                .catch((error) => {
                    setFileIsUploading(false);
                    setSelectedFile(null);
                    setErrorMsg(<h4>Looks like there's an <span className='text-orange'>{error}</span> while uploading this file, I request you to please try again!</h4>);
                });
        })
        .catch((error) => {
            console.error('error:-',error);
            setFileIsUploading(false);
            setSelectedFile(null);
            setErrorMsg(<h4>Looks like there's an <span className='text-orange'>{error}</span> while uploading this file, I request you to please try again!</h4>);
        });
    } catch(error) {
        setFileIsUploading(false);
        setSelectedFile(null);
        setErrorMsg(<h4>Looks like there's an <span className='text-orange'>{error}</span> while uploading this file, I request you to please try again!</h4>);
    }
    // setSelectedFile(file);
}

export function colMappingAndImportInvoice(deskeraRelativePath, setIsCompleted, setSuccessImports, setInvalidImports, setFileIsUploading, setSelectedFile, setErrorMsg) {
    try {
        axios.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.IMPORT_EXPORT.COLUMN_MAPPING}?module=INVOICE&relativeFilePath=${deskeraRelativePath}&startRow=0`, {
            headers: {
                'x-access-token': ApiConstants.ACCESS_TOKEN
            }
        })
        .then((response) => {
            console.log('response: ', response);
            const auditLogId = response.data.auditLogId
            const mapping = response.data.mapping

            axios.post(`${ApiConstants.URL.BASE}${ApiConstants.URL.IMPORT_EXPORT.IMPORT}?module=INVOICE&relativeFilePath=${deskeraRelativePath}&auditLogId=${auditLogId}`,
                { "dataInsight": { "dateFormat": "dd-MM-yyyy", "duplicateRecordOption": "IMPORT", "importOption": "ADD_AND_UPDATE", "invalidValueOption": "REJECT_VALUE" }, "mapping": mapping }, {
                headers: {
                    'x-access-token': ApiConstants.ACCESS_TOKEN
                }
            })
                .then((response) => {
                    console.log('response: ', response);
                    if(response.status === 200) {
                        setSuccessImports(response.data.success);
                        setInvalidImports(response.data.invalid_records);
                        setIsCompleted({"bool":true, "success": response.data.success, "invalid":response.data.invalid_invoices })
                    }
                })
                .catch((error) => {
                    setFileIsUploading(false);
                    setSelectedFile(null);
                    setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
                });
        })
        .catch((error) => {
            setFileIsUploading(false);
            setSelectedFile(null);
            setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
        });
    } catch(err) {
        setFileIsUploading(false);
        setSelectedFile(null);
        setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
    }
}