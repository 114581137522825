import axios from 'axios';
import ApiConstants from './ApiConstants';

export function fileProcessor(formData, setDeskeraRelativePath, setChanges, setCsvFile, setFileIsUploading, setSelectedFile, setErrorMsg) {
  try {
    axios.post(`${ApiConstants.URL.CHAT.BASE_FILE_PROCESSING}/file-processor?module=IMPORT_ACCOUNT`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": ApiConstants.ACCESS_TOKEN,
        },
      }
    )
    .then((response) => {
      try {
        console.log('file transferred. response: ', response);
        const relativePath = response.data.ds_relative_file_path;
        setDeskeraRelativePath(relativePath);
        const changesData = response.data.corrections;
        setChanges(changesData);

        axios.get(
            `${ApiConstants.URL.BASE}${ApiConstants.URL.CHAT.ATTACHMENT_FILE_DOWNLOAD}${relativePath}`,
            {
              headers: {
                Accept: "*/*",
                "x-access-token": ApiConstants.ACCESS_TOKEN,
              },
            }
          )
          .then((response) => {
            try {
              // console.log('file downloaded from relative path. response:', response);
              const downloadURL = response.data;
              axios.get(`${downloadURL}`, {
                  headers: {
                    Accept: "*/*",
                    "x-access-token": ApiConstants.ACCESS_TOKEN,
                  },
                })
                .then((response) => {
                  // console.log('downloadURL: ', response);
                  setCsvFile(response.data);
                  // saveAs(new Blob([response.data]), './reverted.csv');
                  setFileIsUploading(false);
                })
                .catch((error) => {
                  setFileIsUploading(false);
                  setSelectedFile(null);
                  // showToast('Error in uploading file. Please try again.', 'failure', 'top');
                  setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
                });
            } catch (error) {
              setFileIsUploading(false);
              setSelectedFile(null);
              // showToast('Error in uploading file. Please try again.', 'failure', 'top');
              setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
            }
          })
          .catch((error) => {
            setFileIsUploading(false);
            setSelectedFile(null);
            // showToast('Error in uploading file. Please try again.', 'failure', 'top');
            setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
          });
      } catch (error) {
        setFileIsUploading(false);
        setSelectedFile(null);
        // showToast('Error in uploading file. Please try again.', 'failure', 'top');
        setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
      }
    })
  //   .then((response) => {
  //     setSelectedFile(file);
  //   })
    .catch((error) => {
      setFileIsUploading(false);
      setSelectedFile(null);
      // showToast('Error in uploading file. Please try again.', 'failure', 'top');
      setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
    });
  } catch(err) {
    setFileIsUploading(false);
    setSelectedFile(null);
    // showToast('Error in uploading file. Please try again.', 'failure', 'top');
    setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
  }
}

export function colMappingAndImportCoa(moduleName, deskeraRelativePath, tenantDetails, selectedFile, setFileIsUploading, setCoaSuccessImports, setCoaInvalidImports, setModuleName, setDeskeraRelativePath, setChanges, setCsvFile, setSelectedFile, setErrorMsg, setObInvalidImports, setObSuccessImports, sendDataToParent, setIsCompleted) {
  try {
    axios.get(
      `${ApiConstants.URL.BASE}${ApiConstants.URL.IMPORT_EXPORT.COLUMN_MAPPING}?module=${moduleName}&relativeFilePath=${deskeraRelativePath}&startRow=0`,
      {
        headers: {
            "x-access-token": ApiConstants.ACCESS_TOKEN,
        },
      }
    )
    .then((response) => {
        console.log(`${moduleName}-mapping (response): `, response);
        const auditLogId = response.data.auditLogId;
        const mapping = response.data.mapping;
        axios.post(
            `${ApiConstants.URL.BASE}${ApiConstants.URL.IMPORT_EXPORT.IMPORT}?module=${moduleName}&relativeFilePath=${deskeraRelativePath}&auditLogId=${auditLogId}`,
            {
            dataInsight: {
                dateFormat: "dd-MM-yyyy",
                duplicateRecordOption: "IMPORT",
                invalidValueOption: "REJECT_VALUE",
            },
            mapping: mapping,
            },
            {
            headers: {
                "x-access-token": ApiConstants.ACCESS_TOKEN,
            },
            }
        )
        .then((response) => {
            setFileIsUploading(false);
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("currency", tenantDetails.currency);
            if(response.status === 200) {
            console.log(`${moduleName}-import response(200): `, response);
            if(moduleName==="ACCOUNT") {
              setCoaSuccessImports(response.data.success);
              setCoaInvalidImports(response.data.invalid_records);

              setModuleName("ACCOUNT_OPENING");
              axios.post(`${ApiConstants.URL.CHAT.BASE_FILE_PROCESSING}/file-processor?module=IMPORT_OPENING_BALANCE`,
              formData,
              {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "x-access-token": ApiConstants.ACCESS_TOKEN,
                },
              }
            )
            .then((response) => {
              try {
              const relativePath = response.data.ds_relative_file_path;
              setDeskeraRelativePath(relativePath);
              const changesData = response.data.corrections;
              console.log('response.data:', response.data);
              setChanges(changesData);

              axios.get(
                  `${ApiConstants.URL.BASE}${ApiConstants.URL.CHAT.ATTACHMENT_FILE_DOWNLOAD}${relativePath}`,
                  {
                      headers: {
                      Accept: "*/*",
                      "x-access-token": ApiConstants.ACCESS_TOKEN,
                      },
                  }
                  )
                  .then((response) => {
                  try {
                      console.log('Opening balance (attachment file dwnload) response:', response);
                      const downloadURL = response.data;
                      axios.get(`${downloadURL}`, {
                          headers: {
                          Accept: "*/*",
                          "x-access-token": ApiConstants.ACCESS_TOKEN,
                          },
                      })
                      .then((response) => {
                          // console.log('downloadURL: ', response);
                          setCsvFile(response.data);
                          // saveAs(new Blob([response.data]), './reverted.csv');
                          setFileIsUploading(false);
                      })
                      .catch((error) => {
                          setFileIsUploading(false);
                          setSelectedFile(null);
                          // showToast('Error in uploading file. Please try again.', 'failure', 'top');
                          setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
                      });
                  } catch (error) {
                      setFileIsUploading(false);
                      setSelectedFile(null);
                      // showToast('Error in uploading file. Please try again.', 'failure', 'top');
                      setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
                  }
                  })
                  .catch((error) => {
                  setFileIsUploading(false);
                  setSelectedFile(null);
                  // showToast('Error in uploading file. Please try again.', 'failure', 'top');
                  setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
                  });
              } catch (error) {
              setFileIsUploading(false);
              setSelectedFile(null);
              // showToast('Error in uploading file. Please try again.', 'failure', 'top');
              setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
              }
            })
            // .then((response) => {
            //   setSelectedFile(file);
            // })
            .catch((error) => {
                setFileIsUploading(false);
                setSelectedFile(null);
                // showToast('Error in uploading file. Please try again.', 'failure', 'top');
                setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
            });
            } else {
                setObInvalidImports(response.data.invalid_records);
                setObSuccessImports(response.data.success);
                sendDataToParent(true);
                setIsCompleted(true);
            }
            } 
        })
        .catch((error) =>
            console.log("file download error: ", error)
        );
    })
    .catch((error) => {
      setFileIsUploading(false);
      setSelectedFile(null);
      // showToast('Error in uploading file. Please try again.', 'failure', 'top');
      setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
    });
  } catch(err) {
    setFileIsUploading(false);
    setSelectedFile(null);
    // showToast('Error in uploading file. Please try again.', 'failure', 'top');
    setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
  }
}