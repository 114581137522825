import { INPUT_TYPE } from "../utils/Constants";
import TableDataParser from "../helper/TableDataParser";
import { IColumn, IFilterCondition, ITableFilter } from "../model/Table";
export const TABLES = {
  PEOPLE_EXPENSE_CLAIMS: "people_expense_claims",
  PEOPLE_EXPENSE_GROUP: "people_expense_group",
  PEOPLE_EXPENSE_CATEGORY: "people_expense_category",
  PEOPLE_EXPENSE_SYNC_REVERT: "people_expense_sync_revert_je",
  PEOPLE_EXPENSE_GROUP_USER: "people_expense_group_user",
  PEOPLE_EXPENSE_PENDING_CLAIMS: "people_expense_pending_claims",
  PEOPLE_EXPENSE_CLAIMS_BREAKDOWN_GROUP:
    "people_expense_claims_breakdown_group",
  PEOPLE_EXPENSE_CLAIMS_BREAKDOWN_CATEGORY:
    "people_expense_claims_breakdown_category",
  PEOPLE_EXPENSE_MY_CLAIMS: "people_expense_my_claims",
  PEOPLE_EXPENSE_APPROVED_CLAIMS: "people_expense_approved_claims",
  PEOPLE_MULTIPLE_EXPENSE: "people_multi_expense",
};
export const PRODUCTS = {
  EXPENSE: "Expense",
  EXPENSE_TREND: "Expense_Trend",
};
export const COLUMN_CODE = {
  PEOPLE_EXPENSE_CLAIMS: {
    EXPENSE_ID: "code",
    CATEGORY: "category",
    DATE: "spendDate",
    APPLICANT: "userName",
    EXPENSE_GROUP: "groupName",
    MERCHANT: "merchant",
    TOTAL_AMOUNT: "totalAmount",
    STATUS: "status",
    GROUP: "expenseGroup",
  },
  PEOPLE_EXPENSE_GROUP: {
    NAME: "name",
    MEMBER_COUNT: "memberCount",
  },
  PEOPLE_EXPENSE_CATEGORY: {
    NAME: "name",
    GROUP_NAME: "groupName",
  },
  PEOPLE_EXPENSE_SYNC_REVERT_JE: {
    EXPENSE_ID: "code",
    CATEGORY: "category",
    DATE: "spendDate",
    APPLICANT: "userName",
    EXPENSE_GROUP: "groupName",
    MERCHANT: "merchant",
    TOTAL_AMOUNT: "totalAmount",
    DATE_OF_JE: "dateOfJE",
    DATE_OF_REVERSAL_JE: "dateOfReversalOfJE",
  },
  PEOPLE_EXPENSE_GROUP_USER: {
    NAME: "name",
    ROLE: "role",
  },
  PEOPLE_MULTIPLE_EXPENSE: {
    DATE: "spendDate",
    EXPENSE_ID: "id",
    MERCHANT: "merchant",
    TOTAL_AMOUNT: "totalAmount",
    TAX_AMOUNT: "taxAmount",
    OTHER_DETAILS: "otherDetails",
    TAX_CODE: "taxCode",
    CATEGORY_ID: "categoryId",
    ATTACHMENTS: "attachments",
    ATTACHMENTS_ID: "attachments_id",
    DESCRIPTION: "otherDetails",
    STATUS: "status",
  },
};
export const TABLE_DISPLAY_NAME = {
  [PRODUCTS.EXPENSE_TREND]: `Expense Claims Trend`,
  [TABLES.PEOPLE_EXPENSE_CLAIMS]: "Expense Claims",
  [TABLES.PEOPLE_EXPENSE_GROUP]: "Expenses Groups",
  [TABLES.PEOPLE_EXPENSE_CATEGORY]: "Categories",
  [TABLES.PEOPLE_EXPENSE_GROUP_USER]: "Expense Group User",
  [TABLES.PEOPLE_EXPENSE_PENDING_CLAIMS]: "Claims to Review",
  [TABLES.PEOPLE_EXPENSE_CLAIMS_BREAKDOWN_CATEGORY]: "My Categories Breakdown",
  [TABLES.PEOPLE_EXPENSE_CLAIMS_BREAKDOWN_GROUP]: "Group Claims Breakdown",
  [TABLES.PEOPLE_EXPENSE_MY_CLAIMS]: "My Claims",
  [TABLES.PEOPLE_EXPENSE_APPROVED_CLAIMS]: "Claims to Reimburse",
};
export class TableManger {
  static tableMap = new Map();
  static populateViewMap(data) {
    data.forEach((item) => {
      this.tableMap.set(item["name"], TableDataParser.parseTableData(item));
    });
    // TableDataParser.checkAndReplaceSegment();
  }
  static setTableColumns(columnData, tableName) {
    this.tableMap.set(tableName, {
      ...this.tableMap.get(tableName),
      ...TableDataParser.parseTableData(columnData),
    });
    // TableDataParser.checkAndReplaceSegment();
  }
  static updateColumn(columnCode, tableName, columnData) {
    let columnsIndex = this.tableMap
      .get(tableName)
      .columns.findIndex((column) => column.columnCode === columnCode);
    let columns = this.tableMap.get(tableName).columns;
    columns.splice(columnsIndex, 1, columnData);
    this.tableMap.set(tableName, { ...this.tableMap.get(tableName), columns });
  }
  static getTableId(tableName) {
    return this.tableMap.get(tableName).id;
  }
  static getTableName(tableName) {
    return this.tableMap.get(tableName).name;
  }
  static getTableColumns(tableName) {
    return this.tableMap.get(tableName).columns;
  }
  static getTableVisibleColumns(tableName) {
    return this.getTableColumns(tableName).filter((column) => !column.hidden);
  }
  static getTableImportColumns(
    tableName,
    excludedColumns
  ) {
    return this.getTableColumns(tableName).filter((column) => {
      const isExcluded =
        excludedColumns && excludedColumns.includes(column.columnCode);
      const isEditable = column.uiVisible && column.editable;

      return !isExcluded && isEditable;
    });
  }
  static getColumnId(tableName, columnCode) {
    let column = this.getColumn(tableName, columnCode);
    return column ? column.id : undefined;
  }
  static getColumn(tableName, columnCode) {
    return this.getTableColumns(tableName).find(
      (column) =>
        (column.columnCode ? column.columnCode : column.name) === columnCode
    );
  }
  static getColumnById(tableName, columnCode) {
    return this.getTableColumns(tableName).find(
      (column) => (column.id ? column.id : column.name) === columnCode
    );
  }
  static getFilterObject(data) {
    let filterObject = {
      logicalOperator: "and",
      conditions: [],
    };
    data.forEach((item) =>
      filterObject.conditions.push(this.getFilterSubCondition(item))
    );
    return filterObject;
  }
  static getFilterSubCondition(item) {
    return {
      colId: item.key,
      value: item.value,
      opr: item.condition,
    };
  }
  static isSelectField(column) {
    return (
      column.type === INPUT_TYPE.SELECT ||
      column.type === INPUT_TYPE.MULTI_SELECT ||
      column.type === "ref"
    );
  }
  static getTableFilteredColumns(
    tableName,
    condition,
    filterEditable
  ) {
    return this.getTableColumns(tableName).filter((column) => {
      const isIncluded = condition(column);
      const isEditable = column.uiVisible && column.editable;

      return filterEditable ? isIncluded && isEditable : isIncluded;
    });
  }
}
