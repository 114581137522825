import React, { Component } from "react";
import { DKLabel, DKButton, DKSpinner } from "deskera-ui-library";

import RouteManager from "../../managers/RouteManager";
import DashboardCard from "../dashboard/DashboardCard";
import { TABLES, PRODUCTS, TableManger } from "../../managers/TableManager";
import { ADMIN } from "../../managers/common/RouteAuth";
import { withTranslation } from "react-i18next";
import ic_settings from "../../assets/icons/ic_settings.png";
import CardRearrangePopup from "../CardRearrangePopup";
import DashboardService from "../../services/DashboardService";

const MODULES = [
 
  
];
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRearrangePopup: false,
      sequence: null,
    };
  }

  componentDidMount() {
    RouteManager.setPresenter(this);
    this.fetchDashboardSettings();
    this.setState({ showRearrangePopup: false });
  }

  render() {
    return (
      <div className="parent-width">
        <div className="row justify-content-between">
          <DKLabel text={this.props.t("DASHBOARD")} className="fw-m fs-l" />
          {this.props.accessedBy === ADMIN && (
            <DKButton
              title="Rearrange"
              className="border-m bg-white"
              icon={ic_settings}
              onClick={() => this.toggleChartRearrangePopup()}
            />
          )}
        </div>
        <div className="row bg-deskera-secondary p-r border-radius-m border-m mt-m justify-content-between banner-holder">
          <DKLabel text='<span style=" font-size:18px">🧑🏻‍💻</span> Visit out community center to get all updates and learn about People+ <span style=" font-size:18px">🎉</span>' />
          <div>
            <div className="row flex-wrap">
              <DKButton
                className="border-m bg-white"
                title="Join Community 👏 "
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/groups/deskera/",
                    "_blank"
                  );
                }}
              />
              <DKButton
                className="border-m ml-r vertical-spacing bg-white"
                title="Learn People+ 🙇‍♂️"
                onClick={() => {
                  window.open(
                    "https://www.deskera.com/blog/tag/people/",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </div>
        {this.props.accessedBy === ADMIN && (
          <div className="row flex-wrap mt-r justify-content-between align-items-stretch dashboard-card-wrapper pb-xxl">
            {this.state.sequence != null &&
              this.state.sequence.map(
                (module) =>
                  (module.visible != null ? module.visible : true) && (
                    <DashboardCard
                      key={module.name}
                      module={module.name}
                      isGraphDesign={module.isGraphDesign}
                    />
                  )
              )}
          </div>
        )}
        {this.state.showRearrangePopup && (
          <CardRearrangePopup
            onClose={() => this.toggleChartRearrangePopup()}
            sequence={this.state.sequence}
            onSequenceChange={(sequence) => this.onSequenceChange(sequence)}
          />
        )}
      </div>
    );
  }

  onSequenceChange(sequence) {
    this.setState({ sequence });
    this.updateDashboardSettings(sequence);
  }

  toggleChartRearrangePopup() {
    this.setState({ showRearrangePopup: !this.state.showRearrangePopup });
  }

  fetchDashboardSettings() {
    DashboardService.getDashboardSetting()
      .then((response) => {
        if (response?.dashboardSettingsPeople?.order?.length > 0) {
          this.setState({ sequence: response.dashboardSettingsPeople.order });
        } else {
          this.setState({ sequence: MODULES });
        }
      })
      .catch((err) => {
        this.setState({ sequence: MODULES });
      });
  }

  updateDashboardSettings(sequence) {
    const data = { dashboardSettingsPeople: { order: sequence } };
    console.log(JSON.stringify(data));
    DashboardService.updateDashboardSetting(data)
      .then((response) => {
        if (response?.success) {
          this.fetchDashboardSettings();
        }
      })
      .catch((err) => {});
  }
}

export default withTranslation()(Dashboard);

export const getDashboardWidgetLoading = () => {
  return (
    <div className="parent-width parent-height row justify-content-center">
      <DKSpinner title="Loading..." />
    </div>
  );
};
