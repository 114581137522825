import React, { useState, useRef, useEffect, Component } from 'react';
import axios from 'axios';
import { DKSpinner, DKIcon, DKInput, DKIcons, DKSearchBar } from 'deskera-ui-library';
import ic_profile_pic from "../../assets/menu/ic_profile_pic.png";
import Product from "./import_data_from_other_platforms/QuickBooks/Product";
import ChartOfAccounts from "./import_data_from_other_platforms/QuickBooks/ChartOfAccounts";
import Contacts from "./import_data_from_other_platforms/QuickBooks/Contacts";
import Invoices from './import_data_from_other_platforms/QuickBooks/Invoices';
import TenantManager from '../../managers/TenantManager';
import ApiConstants from '../../utils/ApiConstants';
import { v4 as uuidv4 } from 'uuid';
import MarkdownIt from 'markdown-it';
import io from 'socket.io-client';
import SpinnerText from '../common/SpinnerText';
import ChatHeader from './ChatHeader';
import ChatConversation from './ChatConversation';
import ChatInput from './ChatInput';
import ChatSidebar from './ChatSidebar';
import renderGraph from '../../utils/GraphUtils';
const componentMap = {
    'import_data_from_other_platforms/quickbooks/product': <Product />,
    'import_data_from_other_platforms/quickbooks/chart of accounts': <ChartOfAccounts />,
    'import_data_from_other_platforms/quickbooks/contacts': <Contacts />,
    'import_data_from_other_platforms/quickbooks/invoices': <Invoices />,
}

const SOCKET_SERVER_URL = "https://chatactions-api-dev.deskera.xyz/";
export default function ChatBody() {
    const [socket, setSocket] = useState(null);
    const [receivedData, setReceivedData] = useState("");
    const [finalData, setFinalData] = useState("");
  
    const [chatLog, setChatLog] = useState(
        [
            {
                user: 'bot',
                message: <p>Hello! How may I help you?</p>,
                id: uuidv4()
            },
        ]
    );
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const [showSidebar, setShowSidebar] = useState(pageWidth>=1300 ? true : false);
    
    const chatLogRef = useRef(null);

    useEffect(() => {
        // Function to update pageWidth when the window is resized
        function handleResize() {
            setPageWidth(window.innerWidth);
        }

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); 

    useEffect(() => {
        console.log('input updated:', input);
    }, [input])

    useEffect(() => {
        console.log('chatLog:', chatLog);
    }, [chatLog])

    useEffect(() => {
        // console.log(pageWidth);
        if(pageWidth<1300) {
            setShowSidebar(false);
        }
    }, [pageWidth])

    useEffect(() => {
        // console.log(showSidebar)
    }, [showSidebar])


    useEffect(() => {
        // console.log("hello")
        const s = io(SOCKET_SERVER_URL);

        setSocket(s);
        // console.log("data s: ", s)
        s.on('connect', () => {
            console.log("socket connected")
        });
        s.on('data_response', (data) => {
            setLoading(false)
            // console.log("data resp: ", data)
            const md = new MarkdownIt();
            const htmlMsg = md.render(data);
            setChatLog(prevChatLog => [
                ...prevChatLog.slice(0, -1),
                { 
                    user: 'bot', 
                    message: <div className='markdown-table' dangerouslySetInnerHTML={{ __html: htmlMsg }} />,
                    id: uuidv4()
                }
            ]);
        });

        s.on('graph_insight', (data) => {
            setLoading(false)
            // console.log("data resp: ", data)
            const md = new MarkdownIt();
            const htmlMsg = md.render(data);
            setChatLog(prevChatLog => {
                const prevChat = prevChatLog[prevChatLog.length-1]
                // console.log('getting insights')
                return ([
                    ...prevChatLog.slice(0, -1),
                    { 
                        user: 'bot', 
                        message: prevChat.message,
                        graph: {
                            ...prevChat.graph,
                            insight: <div dangerouslySetInnerHTML={{ __html: htmlMsg }} />
                        },
                        id: uuidv4()
                    }
                ])
            });
        });

        
        s.on('graph_vals', (data) => {
            setLoading(false)
            console.log("graph_vals: ", data)
            // const md = new MarkdownIt();
            // const htmlMsg = md.render(data);
            setChatLog(prevChatLog => {
                const prevChat = prevChatLog[prevChatLog.length-1]
                console.log('received graph data:', data);
                return ([
                    ...prevChatLog.slice(0, -1),
                    { 
                        user: 'bot', 
                        message: prevChat.message,
                        graph: {
                            ...prevChat.graph,
                            graph_data: renderGraph(data)
                        },
                        id: uuidv4()
                    }
                ])
            });
        });
        
        s.on('graph_outro', (data) => {
            setLoading(false)
            // console.log("data resp: ", data)
            const md = new MarkdownIt();
            const htmlMsg = md.render(data);
            setChatLog(prevChatLog => {
                const prevChat = prevChatLog[prevChatLog.length-1]
                return ([
                    ...prevChatLog.slice(0, -1),
                    { 
                        user: 'bot', 
                        message: prevChat.message,
                        graph: {
                            ...prevChat.graph,
                            outro: <div  dangerouslySetInnerHTML={{ __html: htmlMsg }} />
                        },
                        id: uuidv4()
                    }
                ])
            });
        })

        s.on('resp_progress', (data) => {
            setLoading(false)
            // console.log("data resp: ", data)
            const md = new MarkdownIt();
            const htmlMsg = md.render(data);
            setChatLog(prevChatLog => [
                ...prevChatLog.slice(0, -1),
                { 
                    user: 'bot', 
                    message:  <div><SpinnerText text={data}/></div>,
                    id: uuidv4()
                }
            ]);
        });


        return () => {
            console.log("socket disconnected")
          s.disconnect();
        };
      }, []);



    useEffect(() => {
        if (chatLogRef.current) {
            chatLogRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatLog]);

    useEffect(() => {
        if (!loading) {
            // Automatically focus on the input element after bot's response
            document.getElementById('chat-input').focus();
        }
    }, [loading]);

    async function handleSubmit(e) {
        e && e.preventDefault();
        setLoading(true);
        console.log("socket", socket)
       
        // Update chatLog immediately
        setChatLog(prevChatLog => [
            ...prevChatLog,
            { 
                user: 'me', 
                message: input,
                id: uuidv4()
            },
            { 
                user: 'bot', 
                message: <p><DKSpinner className="mr-l" iconClassName="ic-s" title="" /></p>,
                id: uuidv4()
            }
        ]);
        // Update bot response based on user input
        axios.post(`${ApiConstants.URL.CHAT.BASE_CHAT}/${ApiConstants.URL.CHAT.ANSWER}`, {
            user_message: input,
            tenant_id: TenantManager.getTenantDetails().id
        }, {
            headers: {
              "x-access-token": ApiConstants.ACCESS_TOKEN,
            },
          })
        .then((response) => {
            try{
                var msg = response.data.message;
                var outro = response.data.graphContent && response.data.graphContent.outro;
                var insight = response.data.graphContent && response.data.graphContent.insight;
                var graphData = response.data.graphContent && response.data.graphContent.graph_vals;
                var suggestions = response.data.suggestions_arr;
                var suggestions2 = ["What is our project cashflows for next month?", "What is our projected pnl?", "What is pnl for this month?"];
                console.log('suggestions:', suggestions);
                
                setLoading(false);
                // console.log(response);

                // render component based on filePath
                if(response.data.task) {
                    const taskname = response.data.task.taskname;
                    const taskArguments  = response.data.task.arguments;

                    // Construct the filePath
                    let filePath = taskname;
                    for (const argumentKey in taskArguments) {
                        if (taskArguments.hasOwnProperty(argumentKey)) {
                            filePath += `/${taskArguments[argumentKey]}`;
                        }
                    }

                    // fetch component based on filePath
                    // const Component = componentMap[filePath.toLowerCase()];
                    // console.log('filePath', filePath);
                    if(componentMap[filePath.toLowerCase()]) {
                        setChatLog(prevChatLog => [
                            ...prevChatLog.slice(0, -1),
                            { 
                                user: 'bot', 
                                message: filePath.toLowerCase(),
                                id: uuidv4()
                            }
                        ]);
                    }
                } else {
                    // msg = "Sure! Here is the Profit & Loss statement for this year:\n\nIncome:\n- Sales: $18,459.95\n- Burner Package & Parts Sales: $14,200.00\n\nTotal Income: $33,002.45\n\nCost of Goods Sold:\n- Cost of Goods Sold: $960.22\n\nTotal Cost of Goods Sold: $960.22\n\nGross Profit: $32,042.23\n\nExpenses:\n- Commissions Expense: $1,700.00\n- Stripe Fee: $1.00\n- Round Off (Default): -$0.05\n- Other Expenses: $0.00\n- Miscellaneous Other: $50,000.00\n- Software Expense (Perpetual < $1500): $1,000.00\n\nTotal Expenses: $52,700.00\n\nNet Profit: -$20,657.77\n\nPlease note that the Profit & Loss statement is based on the data provided and may vary based on any additional transactions or adjustments"
                    const md = new MarkdownIt();
                    const htmlMsg = md.render(msg);
                    const htmlMsgOutro = outro && md.render(outro);
                    const htmlMsgInsight = insight && md.render(insight);
                    
                    setChatLog(prevChatLog => {
                        const prevChat = prevChatLog[prevChatLog.length-1]
                        return ([
                            ...prevChatLog.slice(0, -1),
                            { 
                                ...prevChat,
                                message: <div className='markdown-table' dangerouslySetInnerHTML={{ __html: htmlMsg }} />,
                                suggestions: suggestions
                                // user: 'bot', 
                                // message: prevChat.message,
                                // graph: {
                                //     ...prevChat.graph,
                                //     outro: <div  dangerouslySetInnerHTML={{ __html: htmlMsg }} />
                                // },
                                // id: uuidv4()
                            }
                        ])
                    });
                }
            }
            catch(error) {
                setLoading(false);
                setChatLog(prevChatLog => [
                    ...prevChatLog.slice(0, -1),
                    { 
                        user: 'bot', 
                        message: <p>Unexpected error occured</p>,
                        id: uuidv4()
                    }
                ]);
                console.log('Error:', error);
            }
        })
        .catch((error) => {
            setLoading(false);
            setChatLog(prevChatLog => [
                ...prevChatLog.slice(0, -1),
                { 
                    user: 'bot', 
                    message: <p>Unexpected error occured</p>,
                    id: uuidv4()
                }
            ]);
            console.error('Error:', error);
        });
        setInput("");
    }

    function handleChange(e) {
        setInput(e.target.value);
    }
    return (
        <div className={`chat-container d-flex column align-items-center p-s w-80 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} style={{fontFamily: 'Inter'}}>
            {(pageWidth>=1300 || showSidebar) && <ChatSidebar pageWidth={pageWidth} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />}
            <ChatHeader pageWidth={pageWidth} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
            <ChatConversation 
                chatLog={chatLog} 
                chatLogRef={chatLogRef}
                componentMap={componentMap}
                isDarkMode={isDarkMode}
                pageWidth={pageWidth}
            />
            <ChatInput 
                handleSubmit={handleSubmit} 
                input={input} 
                setInput={setInput} 
                loading={loading}
                handleChange={handleChange}
                chatLog={chatLog} 
            />
        </div>
    )
}


