
import React, { useState, useEffect } from 'react';

function SpinnerText({ text }) {
  const [spinnerChar, setSpinnerChar] = useState('\\');

  useEffect(() => {
    const chars = ['\\', '|', '/', '-'];
    let index = 0;
    
    const interval = setInterval(() => {
      index = (index + 1) % chars.length;
      setSpinnerChar(chars[index]);
    }, 100);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  return (
    <span>
      {text}{spinnerChar}
    </span>
  );
}

export default SpinnerText;
