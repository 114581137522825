import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DKButton, DKSpinner, DKIconText, DKIcons, showToast } from "deskera-ui-library";
import ApiConstants from '../../../../utils/ApiConstants';
import { saveAs } from 'file-saver';
import TenantManager from "../../../../managers/TenantManager";
import { fileProcessor } from '../../../../utils/ContactImportUtils';
import { updateRenderedChanges } from "../../../../utils/ImportUtils";
import { colMappingAndImportContact } from '../../../../utils/ContactImportUtils';
import uploadCloudOutlinedImg from '../../../../assets/chatbot/uploadCloudOutlinedImg.png';
import sampleQbContactFile from '../../../../assets/chatbot/qb/sampleQbContactFile.csv';


export default function Contacts () {
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState(null);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [renderedChanges, setRenderedChanges] = useState([]);
  const [changes, setChanges] = useState({});
  const [csvFile, setCsvFile] = useState("");
  const [buttonStates, setButtonStates] = useState({});
  const [deskeraRelativePath, setDeskeraRelativePath] = useState("");
  const [tenantDetails, setTenantDeatils] = useState(TenantManager.getTenantDetails());
  const [invalidImports, setInvalidImports] = useState(null);
  const [successImports, setSuccessImports] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

    function revertChanges(original, changed, csvFile) {
        setCsvFile((prevCsvFile) => prevCsvFile.replace(changed, original));
    }

    function resetState() {
        console.log('resetting state');
        setCsvFile("");
        setButtonStates({});
        setSelectedFile(null);
        setRenderedChanges([]);
        setChanges({});
    }

    useEffect(() => {
        updateRenderedChanges(buttonStates, csvFile, changes, revertChanges, setButtonStates, setRenderedChanges);
    }, [buttonStates, csvFile, changes]);

    useEffect(() => {
        console.log('errorMsg:', errorMsg);
    }, [errorMsg])

    // 1. [formData]
    useEffect(() => {
        selectedFile
        &&
        fileProcessor(formData, setDeskeraRelativePath, setChanges, setCsvFile, setFileIsUploading);
    }, [formData])

    // 2. [selectedFile]
    useEffect(() => {
        let updatedFormData = new FormData();
        updatedFormData.append("file", selectedFile);
        updatedFormData.append("curency", tenantDetails);
        setFormData(updatedFormData);
    }, [selectedFile])

    
  const handleDownload = async () => {
    const link = document.createElement('a');
    link.href = sampleQbContactFile;
    link.download = 'sampleQbContactFile';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

    const handleFileChange = (e) => {
        setErrorMsg(null);
        setFileIsUploading(true);
        const file = e.target.files[0];
        setSelectedFile(file);
    };



    return (
       isCompleted.bool
       ? 
       <div>
            Successfully imported {isCompleted.invalid.length === 0 && "all the"} {isCompleted.success.length} contacts<br></br>
            {isCompleted.invalid && isCompleted.invalid.length > 0 && `Failed ${isCompleted.invalid.length} contact(s) to import.`}
            {isCompleted.invalid.length > 0 && "Failure reasons: "}

            <div style={{maxHeight: '35vh', overflowY: 'auto'}}>
                {isCompleted.invalid && isCompleted.invalid.map((item, index) => (
                    <li key={index}><b>{item.name}</b> <span className='text-orange'>failed</span> to import due to {
                        item.errors.map((error) => (
                            <span>{error} , </span>
                            ))
                        }</li>
                        ))}
            </div>
            <h3 className='text-green'>Customer File Import Successful!</h3>
            {errorMsg}
        </div>
       :
       <div className='import-qb-contact-container flex column'>
       {
            !selectedFile && 
            <div className='upload-qb-contact-file-container column align-items-center bg-white border-radius-s shadow-s p-l'>
                <div className='upload-qb-contact-file-header text-black text-gray mb-s'>Upload Quickbooks Contact file</div>
                <div className='upload-qb-contact-file-btn-container bg-line m-s ml-l mr-l flex column align-items-center p-s border-radius-s text-black'>
                <img class="m-r" src={uploadCloudOutlinedImg} alt="Upload Cloud" />
                <div className='upload-file-btn flex column align-items-center m-s'>
                    <small className='mb-s'>Upload .xlsx, .csv or .xml</small>
                    <input
                    type="file"
                    accept=".xlsx,.csv"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="contact-file-upload-input"
                    disabled={fileIsUploading}
                    />
                    <label htmlFor="contact-file-upload-input">
                    {<DKIconText
                        className="btn btn-primary bg-blue text-white p-s border-radius-m cursor-hand"
                        component="span"
                        icon={DKIcons.white.ic_document}
                        iconClassName=""
                        text={
                        !selectedFile
                            ? "Choose a file"
                            : "Choose another file"
                        }
                        disabled={fileIsUploading}
                        // textClassName="fs-s"
                        onClick={() => resetState()}
                    />}
                    </label>
                </div>
                <div className='sample-file-btn'>
                    <DKIconText
                    className="btn btn-primary p-s border-s border-blue border-radius-m cursor-hand m-xl"
                    component="span"
                    icon={DKIcons.ic_download}
                    iconClassName=""
                    text="Sample File"
                    disabled={fileIsUploading}
                    // textClassName="fs-s"
                    onClick={handleDownload}
                    />
                </div>
                </div>
            </div>
            }
       {
           selectedFile &&
           (
               fileIsUploading
                ?
                <div><DKSpinner className="m-v-m" iconClassName="ic-s" title="Processing your file" isWhite /></div>
                :
                <div className='logs'>
                    <div>
                        <div className="mb-m">This has been uploaded as your QB's File: <b>{selectedFile.name}</b></div>
                        {renderedChanges.length>0 && <div className="logs-container p-s" style={{ overflowY: "auto" }}>{renderedChanges}</div>}
                    </div>
                </div>
           )
       }
       {
            selectedFile 
            && 
            !fileIsUploading 
            && 
            <DKButton 
                className="btn btn-primary bg-blue text-white" 
                component="span" 
                title="Continue" 
                    onClick={() => {
                        setFileIsUploading(true)
                        colMappingAndImportContact(deskeraRelativePath, setIsCompleted, setSuccessImports, setInvalidImports)
                    }
                } 
            />
        }
        {errorMsg}
        </div>
    );
}

