import RouteManager, { PAGE_ROUTES } from "../RouteManager";
import {
  isMobileWebView,
  isViewportLarge,
} from "../../utils/ViewportSizeUtils";
import SideBarService from "../../services/Sidebar";
import ic_dashboard from "../../assets/icons/navigation-icons/white/ic_dashboard.svg";
import ic_expense from "../../assets/icons/navigation-icons/white/ic_expense.svg";
import ic_settings from "../../assets/icons/navigation-icons/white/ic_setting.svg";
import ic_automation from "../../assets/icons/navigation-icons/white/ic_automation.png";
import ic_hiring from "../../assets/icons/navigation-icons/white/ic_hiring.svg";
import ApiConstants from "../../utils/ApiConstants";
import Dashboard from "../../components/dashboard/Dashboard";
// import ExpenseClaims from "../../components/expense/ExpenseClaims";
// import ExpenseIntegationSettings from "../../components/expense-integration-settings/ExpenseIntegrationSettings";
// import ExpenseAdminSetting from "../../components/expense/expense-admin-setting/ExpenseAdminSetting";
// import GroupUser from "../../components/expense/expense-admin-setting/GroupUser";

export const ADMIN = "A";
export const EMPLOYEE = "E";
export const ADMIN_EMPLOYEE = "AE";
export const CONTRACTOR = "C";

const getAuthDetails = (componentName) => {
  const auth = {
    accessLevel: "rwd",
  };

  switch (componentName) {
    default:
      auth.accessLevel = "rwd";
      break;
  }

  return auth;
};

export const ROUTES = [
  {
    accessTo: "AE",
    country: ["-1"],
    route: "/",
    render: (props) => (
      <Dashboard {...getAuthDetails("DASHBOARD")} {...props} />
    ),
  },
  // {
  //   accessTo: "AE",
  //   country: ["-1"],
  //   route: PAGE_ROUTES.EXPENSE.EXPENSE_CLAIM,
  //   render: (props) => (
  //     <ExpenseClaims {...getAuthDetails("EXPENSE_CLAIMS")} {...props} />
  //   ),
  // },
  // {
  //   accessTo: "A",
  //   country: ["IN", "US"],
  //   route: PAGE_ROUTES.EXPENSE.EXPENSE_INTEGARTION_SETTINGS,
  //   render: (props) => (
  //     <ExpenseIntegationSettings
  //       {...getAuthDetails("EXPENSE_INTEGRATION_SETTINGS")}
  //       {...props}
  //     />
  //   ),
  // },
  // {
  //   accessTo: "A",
  //   country: ["IN", "US"],
  //   route: PAGE_ROUTES.EXPENSE.EXPENSE_ADMIN_SETTING,
  //   render: (props) => (
  //     <ExpenseAdminSetting
  //       {...getAuthDetails("EXPENSE_ADMIN_SETTING")}
  //       {...props}
  //     />
  //   ),
  // },
  // {
  //   accessTo: "A",
  //   country: ["IN", "US"],
  //   route: PAGE_ROUTES.EXPENSE.EXPENSE_GROUP_USER,
  //   render: (props) => (
  //     <GroupUser {...getAuthDetails("EXPENSE_GROUP_USER")} {...props} />
  //   ),
  // },
  // {
  //   accessTo: "A",
  //   country: ["IN", "US"],
  //   route: PAGE_ROUTES.EXPENSE.EXPENSE_GROUP_USER + "/:id/:name",
  //   render: (props) => (
  //     <GroupUser {...getAuthDetails("EXPENSE_GROUP_USER")} {...props} />
  //   ),
  // },
];

export const NAVIGATIONS = [

];
