import React, { Component } from "react";
import { DKLabel, DKIcon, DKButton, DKLine } from "deskera-ui-library";
import {
  PRODUCTS,
  TABLES,
  TABLE_DISPLAY_NAME,
} from "../../managers/TableManager";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import ApiConstants from "../../utils/ApiConstants";
import ExpenseClaimTrends from "./ExpenseClaimTrendsCard";
import PendingClaimsCard from "./PendingClaimsCard";
import ApprovedClaimsCard from "./ApprovedClaimsCard";
import ClaimsByCategoryCard from "./ClaimsByCategoryCard";
import MyClaimsCard from "./MyClaimsCard";

/*
PROPS
- product
- isGraphDesign
*/

class DashboardCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
    };
  }
  render() {
    return (
      <>
        {!this.props.isGraphDesign && (
          <div className="bg-white p-h-xl pt-r pb-l border-m border-radius-m border-box dashboard-card column align-items-center parent-width">
            <div
              className="column align-items-start parent-width custom-scroll"
              style={{
                overflow: "hidden",
              }}
            >
              <div className="row align-items-center">
                <DKLabel
                  text={TABLE_DISPLAY_NAME[this.props.module]}
                  className="fw-m fs-m"
                />
                {this.getTotalCountView()}
              </div>
            </div>
            <div
              className=" mt-m mb-s parent-width  custom-scroll"
              style={{
                height: 190,
                overflowX: "hidden",
                scrollbarWidth: "thin",
              }}
            >
              {this.getProductCard()}
            </div>
            <DKLine />
            {this.props.module !== TABLES.PEOPLE_EXPENSE_APPROVED_CLAIMS && (
                <div className="row-reverse mt-l">
                  {this.props.module !== TABLES.PEOPLE_EXPENSE_MY_CLAIMS && (
                    <DKButton
                      title={this.getAddButtonText()}
                      className="bg-app text-white fs-r ml-m"
                      onClick={() => this.onCreateNew(this.props.module)}
                    />
                  )}
                  {this.props.module !== TABLES.PEOPLE_EXPENSE_PENDING_CLAIMS &&
                    this.props.module !==
                      TABLES.PEOPLE_EXPENSE_APPROVED_CLAIMS && (
                      <DKButton
                        onClick={() =>
                          this.viewDetailsHandler(this.props.module)
                        }
                        title="View All"
                        className="bg-gray1 border-m"
                      />
                    )}
                </div>
              )}
          </div>
        )}
        {this.props.isGraphDesign && this.getProductCard()}
      </>
    );
  }
  onCountUpdated = (count) => {
    this.setState({
      totalCount: count,
    });
  };
  onCreateNew = (module) => {
    switch (module) {
      default:
        break;
    }
  };
  getProductCard() {
    switch (this.props.module) {
      case PRODUCTS.EXPENSE_TREND:
        return <ExpenseClaimTrends />;
      case TABLES.PEOPLE_EXPENSE_PENDING_CLAIMS:
        return (
          <PendingClaimsCard
            module={this.props.module}
            onCountUpdated={this.onCountUpdated}
          />
        );
      case TABLES.PEOPLE_EXPENSE_APPROVED_CLAIMS:
        return <ApprovedClaimsCard module={this.props.module} />;
      case TABLES.PEOPLE_EXPENSE_CLAIMS_BREAKDOWN_GROUP:
        return <ClaimsByCategoryCard module={this.props.module} type="GROUP" />;
      case TABLES.PEOPLE_EXPENSE_CLAIMS_BREAKDOWN_CATEGORY:
        return (
          <ClaimsByCategoryCard module={this.props.module} type="CATEGORY" />
        );
      case TABLES.PEOPLE_EXPENSE_MY_CLAIMS:
        return <MyClaimsCard module={this.props.module} />;
      default:
        return null;
    }
  }
  viewDetailsHandler = (module) => {
    switch (module) {
      case TABLES.PEOPLE_EXPENSE_MY_CLAIMS:
        return RouteManager.navigateToPage(PAGE_ROUTES.EXPENSE.EXPENSE_CLAIM);
      default:
        return RouteManager.navigateToHome();
    }
  };
  getTotalCountView = () => {
    return (
      <>
        <DKLabel
          className={" ml-s fw-m border-radius-s "}
          text={
            this.state.totalCount > 0 ? `<b>(${this.state.totalCount})</b>` : ""
          }
        />
      </>
    );
  };

  getAddButtonText() {
    switch (this.props.module) {
      case TABLES.PEOPLE_EXPENSE_PENDING_CLAIMS:
        return "Review Now >";
      default:
        return "+ New";
    }
  }
  routeToRespectiveApps(module) {
    let routeTo = "";
    switch (module) {
      case PRODUCTS.PAYROLL:
        routeTo = ApiConstants.PRODUCT_URL_PEOPLE;
        break;
      case PRODUCTS.ELEAVE:
        routeTo = ApiConstants.PRODUCT_URL_ELEAVE;
        break;
      case PRODUCTS.EXPENSE:
        routeTo = ApiConstants.PRODUCT_URL_EXPENSE;
        break;
    }
    window.open(routeTo, "_blank");
  }
}

export default DashboardCard;
