import { DEFAULT_TRIAL_PLAN_DAYS } from "../utils/Constants";
import { PRODUCTS, USER_ROLE, USER_STATUS } from "../utils/Enum";
import Utility from "../utils/Utility";

class UserManager {
  static userDetails = null;
  static usersList = null;
  static userRoleGroup = null;
  static userPlanDetails = null;

  static isUserDataAvailable() {
    return this.userDetails !== null && this.userDetails !== undefined;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getUserDetails = () => {
    return this.userDetails;
  };

  static setUserDetails = (data) => {
    this.userDetails = data;
  };
  static setUserPlanDetails(planDetails) {
    this.userPlanDetails = planDetails;
  }
  static isTrialPlanActive() {
    return this.userPlanDetails?.InTrial && !this.userPlanDetails?.TrialOver;
  }
  static getUserPlanDetails() {
    return this.userPlanDetails;
  }
  static isPlanActive() {
    return this.userPlanDetails?.Subscribed || this.isTrialPlanActive();
  }
  static isTrialPlanAvailable() {
    return !this.userPlanDetails?.InTrial && !this.userPlanDetails?.TrialOver;
  }
  static getTrialDays(inTrialMode = false) {
    /* Trial Days for an unsubscribed user */
    let trialDays = this.userPlanDetails?.TrialDays ?? DEFAULT_TRIAL_PLAN_DAYS;

    /* Pending trial days */
    try {
      if (this.userPlanDetails && inTrialMode) {
        const trialEndTime = new Date(
          this.userPlanDetails.TrialEndDate
        ).getTime();
        const currentTime = new Date().getTime();

        trialDays = Math.ceil(
          (trialEndTime - currentTime) / (1000 * 60 * 60 * 24)
        );
        trialDays = trialDays < 0 ? 0 : trialDays;
      }
    } catch (err) {
      trialDays = 0;
    }

    return trialDays;
  }
  static getUserPlanName() {
    return this.userPlanDetails?.PlanName;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getUserName = () => {
    return this.userDetails ? this.userDetails.name : null;
  };
  static getUserEmail = () => {
    return this.userDetails ? this.userDetails.email : null;
  };
  static getUserPhoneNumber = () => {
    return this.userDetails ? this.userDetails.phone : null;
  };
  static getUserCountry = () => {
    return this.userDetails ? this.userDetails.country : null;
  };
  static getUserCurrency = () => {
    return this.userDetails ? this.userDetails.currency : null;
  };
  static getUserID = () => {
    return this.userDetails ? this.userDetails.id : null;
  };
  static getUserIamID = () => {
    return this.userDetails.imID;
  };
  static getUserTenantID = () => {
    return this.userDetails ? this.userDetails.tenantID : null;
  };
  static getUserTenantName = () => {
    // use this from CompanyDetailManager
    return this.userDetails ? this.userDetails.tenantName : "Company Name";
  };
  static updateTenantName = (name) => {
    this.userDetails = { ...this.userDetails, tenantName: name };
  };
  static isOrgSet = () => {
    if (this.userDetails) {
      if (this.userDetails.isOrgSet === null) {
        return false;
      }
      return true;
    }
    return false;
  };

  static isUserAdmin() {
    if (this.userDetails && this.userDetails.roleShortInfo) {
      return this.userDetails.roleShortInfo.some(
        (role) =>
          role.appName === PRODUCTS.CONSOLE.toUpperCase() &&
          role.shortCode === USER_ROLE.ADMIN.toLowerCase()
      );
    }
    return false;
  }
  static isUserOwner() {
    if (this.userDetails && this.userDetails.roleShortInfo) {
      return this.userDetails.roleShortInfo.some(
        (role) =>
          role.appName === PRODUCTS.ORGANISATION.toUpperCase() &&
          role.shortCode === USER_ROLE.OWNER.toLowerCase()
      );
    }
    return false;
  }

  static getUserOrgRole() {
    if (this.isUserOwner()) {
      return USER_ROLE.OWNER;
    }
    if (this.isUserAdmin()) {
      return USER_ROLE.ADMIN;
    }
    return USER_ROLE.MEMBER;
  }
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getUserLists = () => {
    return this.usersList;
  };

  static setUsersList = (list) => {
    this.usersList = list.sort(function (obj1, obj2) {
      return obj2["id"] - obj1["id"];
    });
  };

  static getAllOrgUsers = (user) => {
    if (!Utility.isEmpty(this.usersList)) {
      let appsArray = [],
        rolesArray = [],
        productAccessArray = [];
      const products = this.usersList.find((obj, key) => obj.id === user.id);

      if (products.roleShortInfo && products.roleShortInfo.length > 0) {
        products.roleShortInfo.forEach((el) => {
          if (el.appName) {
            appsArray.push(el.appName);
          }
          if (el.shortCode) {
            let role = this.getAllRolesByProduct(el.appName).find(
              (role) => role.shortCode === el.shortCode
            );
            rolesArray.push(
              !Utility.isEmpty(role) ? role.name : USER_ROLE.MEMBER
            );
          }
        });
      }

      if (appsArray.length !== 0) {
        appsArray.forEach((el) => {
          if (el.toLowerCase() === PRODUCTS[el]) {
            productAccessArray.push(PRODUCTS[el]);
          }
        });
      }
      const role = this.setAllRoles(rolesArray);
      return {
        productAccessArray: productAccessArray,
        allAppsRole: rolesArray,
        role: role,
      };
    }
  };

  static parseRoleTitle(title) {
    if (title.toLowerCase() === "org_user" || title.toLowerCase() === "user") {
      return USER_ROLE.MEMBER.toLowerCase();
    }
    return title;
  }

  static setAllRoles = (rolesArray) => {
    if (rolesArray.includes(USER_ROLE.OWNER)) {
      return USER_ROLE.OWNER;
    } else {
      if (rolesArray.includes(USER_ROLE.ADMIN)) {
        return USER_ROLE.ADMIN;
      } else {
        if (rolesArray.includes(USER_ROLE.MANAGER)) {
          return USER_ROLE.MANAGER;
        } else {
          return USER_ROLE.MEMBER;
        }
      }
    }
  };

  static getUserRole(user) {
    return USER_ROLE.MEMBER;
  }
  static getUserStatus(status) {
    return USER_STATUS[status.toUpperCase()];
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static addUser = (user) => {
    this.usersList.push(user);
  };
  static getUserRoleGroup() {
    return this.userRoleGroup;
  }
  static setUserRoleGroup(userRoleGroup) {
    this.userRoleGroup = userRoleGroup;
  }
  static getParseUserRoleGroup() {
    let parseRoleGroup = [];
    Object.keys(this.userRoleGroup).forEach((key) => {
      if (!Utility.isEmpty(this.userRoleGroup[key])) {
        parseRoleGroup = [...parseRoleGroup, ...this.userRoleGroup[key]];
      }
    });
    return parseRoleGroup;
  }
  static getRolesByProduct(product, guest) {
    return this.getParseUserRoleGroup().filter(
      (data) => data.appName === product && data.guest === guest
    );
  }
  static getAllRolesByProduct(product) {
    return this.getParseUserRoleGroup().filter(
      (data) => data.appName === product
    );
  }
}

export default UserManager;

// static getRolesByProduct(product, guest = false) {
//   if (guest) {
//     return this.getParseUserRoleGroup().filter(
//       (data) => data.appName === product && data.guest
//     );
//   }
//   return this.getParseUserRoleGroup().filter(
//     (data) => data.appName === product
//   );
// }
