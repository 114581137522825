import React from 'react';
import { DKIcon, DKIcons } from "deskera-ui-library";
import UserManager from '../../managers/UserManager';
import logout from '../../assets/chatbot/logout.svg';


export default function UserInfoBtn() {
  return (
    <button className='d-flex row border-radius-xxl p-m border-m bg-none bg-white mt-m cursor-pointer'  style={{ fontSize:"15px",padding:"18px 15px",  fontWeight:"400", fontFamily:"Inter"}}>
      <DKIcon src={DKIcons.ic_user} className="ic-r" />
      <span className='fw-m ml-m' style={{ fontSize:"15px",fontWeight:"600", fontFamily:"Inter"}}>{UserManager.getUserName()}</span>
      <img src={logout} alt='' className='cursor-pointer' style={{position: 'absolute', right: '10%',scale:"1.7"}}/>
    </button>
  )
}
