import React, { useState, useEffect } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts"

export default function BarGraph({ data }) {

  const [graphData, setGraphData] = useState({});
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Function to update pageWidth when the window is resized
    function handleResize() {
        setPageWidth(window.innerWidth);
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []); 


  useEffect(() => {
    const graphDataArr = data.map((item) => ({
      name: item.title,
      total: item.value
    }))
    setGraphData(graphDataArr);
  }, [data])
  
  return (
    <small className='' style={{overflow: 'auto', padding: '6rem'}}>
      <small><h1>Bar Graph</h1></small>
      <ResponsiveContainer width={pageWidth>=1300 ? '500%' : '100%'} height={245}>
        <BarChart data={graphData}>
          <XAxis
            dataKey="name"
            stroke="#888888"
            fontSize={pageWidth>=1550 ? 10 : 7}
            tickLine={true}
          />
          <YAxis
            stroke="#888888"
            fontSize={12}
            tickLine={true}
            axisLine={true}
            tickFormatter={(value) => `$${value}`}
          />
          <Bar dataKey="total" fill="#B7CBFF" radius={[10, 10, 0, 0]} isAnimationActive={false}/>
        </BarChart>
      </ResponsiveContainer>
    </small>
  )
}


