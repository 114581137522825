import React from 'react';
import ChatMessage from './ChatMessage';

export default function ChatConversation({ chatLog, chatLogRef, componentMap, isDarkMode, pageWidth }) {
  return (
    <div className='chat-message-container custom-scroll d-flex column align-items-center' style={{ overflowY: 'auto', paddingBottom: '10%', marginLeft: '8%', width: '100%' }}>
      {chatLog.map((message, index) => {
            return <ChatMessage 
              key={message.id} 
              message={message} 
              size={chatLog.length} 
              index={index} 
              componentMap={componentMap} 
              isDarkMode={isDarkMode}
              pageWidth={pageWidth}
            />
        })}
        <div ref={chatLogRef} />
    </div>
  )
}
