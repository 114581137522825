import axios from 'axios';
import ApiConstants from './ApiConstants';

export function colMappingAndImportProduct(setIsCompleted, setFileIsUploading, setSuccessImports, setInvalidImports, deskeraRelativePath) {
  // showContinue && deskeraRelativePath
  // &&
  axios.get(
    `${ApiConstants.URL.BASE}${ApiConstants.URL.IMPORT_EXPORT.COLUMN_MAPPING}?module=PRODUCT&relativeFilePath=${deskeraRelativePath}&startRow=0`,
    {
      headers: {
        "x-access-token": ApiConstants.ACCESS_TOKEN,
      },
    }
  )
  .then((response) => {
    console.log("response: ", response);
    const auditLogId = response.data.auditLogId;
    const mapping = response.data.mapping;

    axios.post(
        `${ApiConstants.URL.BASE}${ApiConstants.URL.IMPORT_EXPORT.IMPORT}?module=PRODUCT&relativeFilePath=${deskeraRelativePath}&auditLogId=${auditLogId}`,
        {
          dataInsight: {
            dateFormat: "dd-MM-yyyy",
            duplicateRecordOption: "IMPORT",
            invalidValueOption: "REJECT_VALUE",
          },
          mapping: mapping,
        },
        {
          headers: {
            "x-access-token": ApiConstants.ACCESS_TOKEN,
          },
        }
      )
      .then((response) => {
        if(response.status===200) {
          console.log('FINAL RESPONSE:', response);
          setIsCompleted(true) && setFileIsUploading(false);
          setSuccessImports(response.data.success);
          setInvalidImports(response.data.invalid_products);
        }
      })
      .catch((error) =>
        console.log("file download error: ", error)
      );
    // setSelectedFile(new Blob([downloadURL]), './qb_to_ds_product_localfile.csv');
  })
  .catch((error) => console.log("file download error: ", error));
}

export function fileProcessor(formData, showContinue, setDeskeraRelativePath, setShowContinue, setChanges, setFileIsUploading, setSelectedFile, setErrorMsg, setCsvFile) {
  axios.post(`${ApiConstants.URL.CHAT.BASE_FILE_PROCESSING}/file-processor?module=IMPORT_PRODUCT`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": ApiConstants.ACCESS_TOKEN,
      },
    }
  )
  .then((response) => {
    // setFileIsUploading(true);
    try {
      console.log('file transferred. response: ', response);
      const relativePath = response.data.ds_relative_file_path;
      setDeskeraRelativePath(relativePath);

      if(!showContinue) {
        const changesData = response.data.corrections;
        setChanges(changesData);

        axios.get(
            `${ApiConstants.URL.BASE}${ApiConstants.URL.CHAT.ATTACHMENT_FILE_DOWNLOAD}${relativePath}`,
            {
              headers: {
                Accept: "*/*",
                "x-access-token": ApiConstants.ACCESS_TOKEN,
              },
            }
          )
          .then((response) => {
            try {
              console.log('file downloaded from relative path. response:', response);
              const downloadURL = response.data;
              axios.get(`${downloadURL}`, {
                  headers: {
                    Accept: "*/*",
                    "x-access-token": ApiConstants.ACCESS_TOKEN,
                  },
                })
                .then((response) => {
                  console.log('downloadURL: ', response);
                  setFileIsUploading(false);
                  setShowContinue(true);
                  setCsvFile(response.data);
                  // saveAs(new Blob([response.data]), './reverted.csv');
                })
                .catch((error) => {
                  setFileIsUploading(false);
                  setSelectedFile(null);
                  // showToast('Error in uploading file. Please try again.', 'failure', 'top');
                  setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
                });
            } catch (error) {
              setFileIsUploading(false);
              setSelectedFile(null);
              // showToast('Error in uploading file. Please try again.', 'failure', 'top');
              setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
            }
          })
          .catch((error) => {
            setFileIsUploading(false);
            setSelectedFile(null);
            // showToast('Error in uploading file. Please try again.', 'failure', 'top');
            setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
          });
      }
    } catch (error) {
      setFileIsUploading(false);
      setSelectedFile(null);
      // showToast('Error in uploading file. Please try again.', 'failure', 'top');
      setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
    }
  })
}

export function checkIfNewAccounts(formData, setFileIsUploading, setProcessedFileResponse, setCoaIsSuccessful, setSelectedFile, setErrorMsg) {
  axios.post(`${ApiConstants.URL.CHAT.BASE_FILE_PROCESSING}/checkifnewaccounts`, 
    formData, 
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": ApiConstants.ACCESS_TOKEN,
      }
    }
  )
  .then((response) => {
    // setSelectedFile(file)
    setFileIsUploading(false);
    if(response.data.length > 0) {
      setProcessedFileResponse(response.data);
    } else {
      setCoaIsSuccessful(true);
    }
  })
  .catch((error) => {
    setFileIsUploading(false);
    setSelectedFile(null);
    console.log('error:', error);
    // showToast('Error in uploading file. Please try again.', 'failure', 'top');
    setErrorMsg(<h4>Looks like there's an <span className='text-orange'>error</span> while uploading this file, I request you to please try again!</h4>);
  });
}
